import { Link } from "react-router-dom"
import "../Styles/NavigationTab.css"

export default function NavigationTab({ name, route, completed, spanish, spanishText }) {
  return (
    <Link to={`${route}`}>
      <div className={completed ? "nav-tab-completed" : "nav-tab"}>{spanish ? spanishText : name}</div>
    </Link>
  )
}
