//--- Helper functions repository ---//

//--- util for date conversion in date-picker ---//
export const formatDate = (date) => {
  const dateString = date.toLocaleString()
  if (/^\d*\/\d*\/\d{4}/.test(dateString)) {
    const [m, d, y] = date
      .toLocaleString()
      .split(",")[0]
      .split("/")
      .map((v) => v.padStart(2, "0"))
    return `${y}-${m}-${d}`
  }
  return dateString.split("T")[0]
}

//--- Date formatter for display ---//
export const prettyDate = (s) => {
  const [y, m, d] = s.match(/\d{4}-\d*-\d*/g)[0].split("-")
  return `${m}/${d}/${y}`
}

//--- Time formatter for display ---//
export const prettyTime = (s) => new Date(s).toLocaleTimeString()

//--- Phone formatter for display ---//
export const formatPhone = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return phoneNumberString
}

//--- Strips the image prefix from the base64 string and tests it for injected html. Returns a cleaned base64 string. ---//
export const sanitize = (b64) => {
  const [prefix, code] = b64.split(",")
  const decoded = atob(code)
  if (/<(.+)>.+<\/\1>/g.test(decoded)) {
    const clean = decoded.replace(/<(.+)>.+<\/\1>/g, "")
    return prefix + "," + btoa(clean)
  }
  return b64
}

//--- Job number formatter ---//
export const jobify = (s) => "J" + s.replace(/[^0-9]/g, "")
