export default function PoliceSummary({ info }) {
  return (
    <div className="summary-container">
      <table>
        <tbody>
          <tr>
            <td>Department name</td>
            <td>{info.RespondingDepartment}</td>
          </tr>
          <tr>
            <td>Report #</td>
            <td>{info.ReportNumber}</td>
          </tr>
          {info.CitationNumber && (
            <>
              <tr>
                <td>Citation #</td>
                <td>{info.CitationNumber}</td>
              </tr>
              <tr>
                <td>Recipient</td>
                <td>{info.CitationRecipient.Name}</td>
              </tr>
              <tr>
                <td>Reason</td>
                <td>{info.CitationReason}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}
