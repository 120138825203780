import "../Styles/Person.css"

export default function Employee({ employee, remove }) {
  return (
    <div className="person-container">
      <p>{employee.Name}</p>
      <button className="remove-button" onClick={() => remove(employee.UUID)}>
        remove
      </button>
    </div>
  )
}
