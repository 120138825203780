export default function Spinner({ message }) {
  return (
    <div>
      <h4>{message || ""}</h4>
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    </div>
  )
}
