import { Navigate } from "react-router-dom"
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react"
import { loginRequest } from "../Utils/msalConfig"

export default function Login({ spanish }) {
  //--- Authentication context & login function---//
  const { instance } = useMsal()
  localStorage.clear()
  const handleLoginClick = () => {
    instance.loginRedirect({ ...loginRequest }).catch((err) => console.error(err))
  }

  return (
    <div className="general-info-container">
      <AuthenticatedTemplate>
        <Navigate to="/" />
      </AuthenticatedTemplate>
      <h2>{(spanish && "Bienvenido") || "Welcome"}</h2>
      <div className="login-container">
        <button className="new-existing-btn" onClick={handleLoginClick}>
          {(spanish && "Empezar") || "Begin a new Report"}
        </button>
      </div>
    </div>
  )
}
