export class ViolationsModel {
  constructor() {
    this.Attendance = false
    this.Carelessness = false
    this.Disobedience = false
    this.Safety = false
    this.Tardiness = false
    this.WorkQuality = false
    this.Other = false
    this.Comments = null
    this.Warning = null
  }
}
