import { useState } from "react"
import { PPEModel } from "../Classes/PPEModel"

export default function PPE({ ppe, add, edit, remove, check, com, missing, toggle, improper }) {
  const [comments, setComments] = useState(com)
  return (
    <div className={check ? "ppe-unit-selected" : "ppe-unit"}>
      <span>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor={`${ppe}`} style={{ color: check ? "white" : "black" }}>
            {ppe}
          </label>
          <input
            type="checkbox"
            id={`${ppe}`}
            value={ppe}
            checked={check}
            onChange={(e) => {
              if (e.target.checked) {
                add(new PPEModel(ppe))
              } else remove(ppe)
            }}
          />
        </div>
        {check && (
          <>
            <div className="radio-container">
              <label htmlFor="missing" style={{ color: "white" }}>
                missing
                <input
                  type="radio"
                  id="missing"
                  name={`${ppe}missing/improper`}
                  value="MissingFromPerson"
                  checked={missing}
                  onChange={(e) => {
                    toggle(ppe, e.target.value, e.target.checked)
                  }}
                />
              </label>
              <label htmlFor="improper" style={{ color: "white" }}>
                used improperly
                <input
                  type="radio"
                  id="improper"
                  name={`${ppe}missing/improper`}
                  value="UsedImproperly"
                  checked={improper}
                  onChange={(e) => {
                    toggle(ppe, e.target.value)
                  }}
                />
              </label>
            </div>
            <textarea
              required={check}
              placeholder={ppe === "Other" ? "required" : "comments / information"}
              maxLength="1024"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              onBlur={() => {
                edit(comments, ppe)
              }}
            ></textarea>
          </>
        )}
      </span>
    </div>
  )
}
