import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function AdditionalInfo({ report, user, update, updateWitness, NA, setNA }) {
  //--- State variables ---//
  const [additionalInfo, setAdditionalInfo] = useState(report.AdditionalInformation || "")
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Saves propgress and navigates to summary page ---//
  const handleCommentsSubmit = async (e) => {
    e.preventDefault()
    if (report.ReportType === "WitnessReport") await updateWitness(additionalInfo, user.accessToken)
    else await update(additionalInfo)
    navigate("/ReportSummary")
  }

  return (
    <div className="general-info-container">
      <h2>Additional Information</h2>
      <label htmlFor="addtnlNA" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Is there any other information, supporting items or comments you'd like to add to this report?
      </label>
      <div id="addtnlNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>
      <form onSubmit={(e) => handleCommentsSubmit(e)}>
        {!NA && (
          <textarea
            id="comments"
            rows="10"
            placeholder="Add any other comments or relevant information about this incident you think is important."
            maxLength="1024"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        )}
        <h4>
          If you have any additional documents or photos that you'd like to include, please email them to
          pcisafety@pcius.com along with your name and phone #.
        </h4>
        <div className="save-back-button-container">
          <input type="submit" className="save-back-button" value="View summary" />
        </div>
      </form>
    </div>
  )
}
