import { PersonModel } from "../Classes/PersonModel"

export default function WitnessForm({ addWitness, newWitness, setNewWitness }) {
  const handleAddNewWitnessSubmit = (e) => {
    e.preventDefault()
    addWitness()
    setNewWitness(new PersonModel())
  }

  console.log(newWitness)

  return (
    <div className="people-form-container" id="peopleForm">
      <form onSubmit={(e) => handleAddNewWitnessSubmit(e)}>
        <label htmlFor="witnessName" style={{ color: "white" }}>
          Witness Name:
        </label>
        <input
          type="text"
          id="witnessName"
          required
          placeholder="Enter a name"
          maxLength="35"
          value={newWitness.Name}
          onChange={(e) => setNewWitness((curr) => ({ ...curr, Name: e.target.value }))}
        />
        <label htmlFor="witnessTelephone" style={{ color: "white" }}>
          Witness Phone:
        </label>
        <input
          type="text"
          id="witnessTelephone"
          required={!newWitness.EmailAddress}
          maxLength="16"
          placeholder="Include area code"
          value={newWitness.PhoneNumber}
          onChange={(e) => setNewWitness((curr) => ({ ...curr, PhoneNumber: e.target.value }))}
        />
        <label htmlFor="witnessEmail" style={{ color: "white" }}>
          Witness Email:
        </label>
        <input
          type="email"
          id="witnessEmail"
          required={!newWitness.PhoneNumber}
          maxLength="50"
          placeholder="Enter an email"
          value={newWitness.EmailAddress}
          onChange={(e) => setNewWitness((curr) => ({ ...curr, EmailAddress: e.target.value }))}
        />
        <input type="submit" className="add-witness-button" value="Add Witness" />
      </form>
    </div>
  )
}
