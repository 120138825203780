import { useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
let shouldFetch = true

export default function Loader({ update, setUser, getWitnessToken, fetchReport }) {
  const { instance } = useMsal()
  const [message, setMessage] = useState("Fetching report...")
  const { id } = useParams()
  const navigate = useNavigate()

  const getReport = async () => {
    const token = await getWitnessToken(id)
    const response = await fetchReport(token)
    if (token.status !== 401 && response) {
      setUser({ name: "guest", accessToken: token })
      if (response.data.ReportType === "WitnessReport") instance.setActiveAccount({ name: "guest" })
      update(response.data)
      navigate(
        `/${response.data.ReportType === "Other" ? "Incident" : response.data.ReportType.replace("Report", "")}Info`
      )
    } else {
      setMessage("No such report found. Redirecting...")
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }
  }

  useEffect(() => {
    if (shouldFetch)
      getReport().catch((e) => {
        console.error(e)
        setMessage("We didn't find anything here. Returning home...")
        setTimeout(() => {
          navigate("/")
        }, 2000)
      })
    shouldFetch = false
  })

  return (
    <div className="general-info-container">
      <h2 style={{ color: message === "Fetching report..." ? "white" : "black" }}>{message}</h2>
    </div>
  )
}
