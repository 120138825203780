import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import { violationTypes } from "../Utils/Constants"
import ViolationType from "../Components/ViolationType"
import { ViolationsModel } from "../Classes/ViolationsModel"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function EmployeeDetails({ report, update }) {
  //--- State variables ---//
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Saves progress and navigates to witness information page ---//
  const handleDetailsInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(detailsInfo)
    setSaving(false)
    navigate("/Witnesses")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Employee Documentation Details</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="violation-types">Type of Violation (check all that apply):</label>
        <div id="violation-types" className="violationTypes">
          {violationTypes.map((type) => (
            <ViolationType
              set={setDetailsInfo}
              violationType={type}
              violations={detailsInfo.Violations || new ViolationsModel()}
              key={crypto.randomUUID()}
            />
          ))}
        </div>
        <label htmlFor="descriptionText">Describe the incident in detail:</label>
        <textarea
          rows="16"
          id="descriptionText"
          placeholder="What specific issues were discussed? What possible actions could be taken if this happens again?"
          required
          maxLength="2048"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="locationText">Describe the location of the incident in detail:</label>
        <textarea
          rows="3"
          id="locationText"
          placeholder="Where did the incident take place? What was the employee doing at the time of the incident?"
          required
          maxLength="1024"
          value={detailsInfo.IncidentLocationDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentLocationDescription: e.target.value
            }))
          }
        />
        <label htmlFor="warningIssued">Was a warning issued?</label>
        <div id="warningIssued" className="radio-container">
          <label htmlFor="warningYes">
            YES
            <input
              type="radio"
              id="warningYes"
              name="warning"
              checked={detailsInfo.WarningIssued}
              onChange={() => setDetailsInfo((curr) => ({ ...curr, WarningIssued: true }))}
            />
          </label>
          <label htmlFor="warningNo">
            NO
            <input
              type="radio"
              id="warningNo"
              name="warning"
              checked={!detailsInfo.WarningIssued}
              onChange={() => setDetailsInfo((curr) => ({ ...curr, WarningIssued: false, Warning: "" }))}
            />
          </label>
        </div>
        {detailsInfo.WarningIssued && (
          <>
            <label htmlFor="warning">Warning details:</label>
            <textarea
              id="warning"
              placeholder="What was the specific warning?"
              required={detailsInfo.WarningIssued}
              maxLength="1024"
              value={detailsInfo.Violations.Warning}
              onChange={(e) =>
                setDetailsInfo((curr) => ({ ...curr, Violations: { ...curr.Violations, Warning: e.target.value } }))
              }
            />
          </>
        )}
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/IncidentInfo")}>
            Go Back
          </button>
          <input type="submit" className="save-back-button" value={saving ? "Saving..." : "Save and Continue"} />
        </div>
      </form>
    </div>
  )
}
