import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import DatePicker from "../Components/DatePicker"
import Spinner from "../Components/Spinner"
import { jobify } from "../Utils/HelperFunctions"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function EmployeeInfo({ update, report }) {
  //--- State variables ---//
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report) navigate("/Error")
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  })

  //--- Saves progress and navigates to details page ---//
  const handleGeneralInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(generalInfo)
    setSaving(false)
    navigate("/EmployeeDetails")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      {(report.ReportType !== "None" && (
        <>
          <h2>PCI Employee Documentation</h2>
          <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
            <DatePicker info={generalInfo} set={setGeneralInfo} />
            <label htmlFor="name">Name of person completing this report:</label>
            <input
              type="text"
              id="name"
              required
              maxLength="35"
              placeholder="Your name"
              value={generalInfo.Reporter.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee # of the person completing this report:</label>
            <input
              type="text"
              id="employeeNumber"
              required
              maxLength="4"
              placeholder="Your employee #"
              value={generalInfo.Reporter.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, EmployeeNumber: e.target.value }
                }))
              }
            />
            <label htmlFor="jobNumber">Job # working on at time of incident:</label>
            <input
              type="text"
              id="jobNumber"
              required
              maxLength="7"
              placeholder="Job where the incident occurred"
              value={generalInfo.JobNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  JobNumber: jobify(e.target.value)
                }))
              }
            />
            <label htmlFor="supervisor">Supervisor at the jobsite:</label>
            <input
              type="text"
              id="supervisor"
              maxLength="35"
              placeholder="Supervisor's name"
              value={generalInfo.Supervisor.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Supervisor: { ...curr.Supervisor, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeAffected">Name of the employee involved:</label>
            <input
              type="text"
              id="employeeAffected"
              required
              maxLength="35"
              placeholder="Employee's name"
              value={generalInfo.AffectedEmployee.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Name: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedNumber">Employee # of the employee involved:</label>
            <input
              type="text"
              id="employeeAffectedNumber"
              required
              maxLength="4"
              placeholder="Employee's #"
              value={generalInfo.AffectedEmployee.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    EmployeeNumber: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="vehicleNumber">Vehicle # of vehicle involved:</label>
            <input
              type="text"
              id="vehicleNumber"
              maxLength="16"
              placeholder="If applicable"
              value={generalInfo.VehicleNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  VehicleNumber: e.target.value
                }))
              }
            />
            <label htmlFor="equipmentNumber">Equipment # of equipment involved:</label>
            <input
              id="equipmentNumber"
              type="text"
              maxLength="16"
              placeholder="If applicable"
              value={generalInfo.EquipmentNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  EquipmentNumber: e.target.value
                }))
              }
            />
            <label htmlFor="safetyTeamInput">Supervisor the incident was reported to:</label>
            <input
              id="safetyTeamInput"
              type="text"
              maxLength="35"
              placeholder="Supervisor's name"
              value={generalInfo.SupervisorReportedTo.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  SupervisorReportedTo: {
                    ...curr.SupervisorReportedTo,
                    Name: e.target.value
                  }
                }))
              }
            />
            <div className="save-back-button-container">
              <button className="save-back-button">Go Back</button>
              <input type="submit" className="save-back-button" value={saving ? "Saving..." : "Save and Continue"} />
            </div>
          </form>
        </>
      )) || <Spinner />}
    </div>
  )
}
