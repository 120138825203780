//--- General repository for constants & useful information ---//

//--- controller for rendering page buttons on different report types ---//
export const pagesController = {
  None: [{ name: "Home", spanishText: "Hogar", route: "" }],
  Accident: [
    { name: "Info", spanishText: "Info.", route: "AccidentInfo" },
    { name: "Details", spanishText: "Detalles", route: "AccidentDetails" },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Police", spanishText: "Policía", route: "PoliceInfo" },
    { name: "Hospital", spanishText: "Hospital", route: "HospitalInfo" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  Employee: [
    { name: "Info", spanishText: "Info.", route: "EmployeeInfo" },
    { name: "Details", spanishText: "Detalles", route: "EmployeeDetails" },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Police", spanishText: "Policía", route: "PoliceInfo" },
    { name: "Hospital", spanishText: "Hospital", route: "HospitalInfo" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  Injury: [
    { name: "Info", spanishText: "Info.", route: "InjuryInfo" },
    { name: "Details", spanishText: "Detalles", route: "InjuryDetails" },
    {
      name: "Info form",
      spanishText: "Info. para Empleado",
      route: "InjuredEmployeeInfo"
    },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Hospital", spanishText: "Hospital", route: "HospitalInfo" },
    { name: "PPE", spanishText: "EPP", route: "PPE" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  UtilityStrike: [
    { name: "Info", spanishText: "Info.", route: "UtilityStrikeInfo" },
    { name: "Details", spanishText: "Detalles", route: "UtilityStrikeDetails" },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Police", spanishText: "Policía", route: "PoliceInfo" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  Other: [
    { name: "Info", spanishText: "Info.", route: "IncidentInfo" },
    { name: "Details", spanishText: "Detalles", route: "IncidentDetails" },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Police", spanishText: "Policía", route: "PoliceInfo" },
    { name: "Hospital", spanishText: "Hospital", route: "HospitalInfo" },
    { name: "PPE", spanishText: "EPP", route: "PPE" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  DamageOrTheft: [
    { name: "Info", spanishText: "Info.", route: "DamageTheftInfo" },
    { name: "Details", spanishText: "Detalles", route: "DamageTheftDetails" },
    { name: "Witnesses", spanishText: "Testigos", route: "Witnesses" },
    { name: "Camera", spanishText: "Fótos", route: "Camera" },
    { name: "Police", spanishText: "Policía", route: "PoliceInfo" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ],
  WitnessReport: [
    { name: "Info", spanishText: "Info.", route: "WitnessInfo" },
    { name: "Details", spanishText: "Detalles", route: "WitnessDetails" },
    { name: "Adt'l Info", spanishText: "Otra info.", route: "AdditionalInfo" },
    { name: "Summary", spanishText: "Summario", route: "ReportSummary" }
  ]
}

//--- Violation Types Array ---//
export const violationTypes = [
  "Attendance",
  "Carelessness",
  "Disobedience",
  "Safety",
  "Tardiness",
  "Work Quality",
  "Other"
]

//--- PPE array - needs to be loaded from API??? ---//
export const ppeList = [
  "Protective gloves",
  "Hearing protection",
  "Safety vest",
  "Face protection",
  "Safety glasses",
  "Ear plugs",
  "Hard hat",
  "Lace-Up work boots",
  "Seatbelt",
  "N95 mask",
  "Fall harness",
  "Other"
]

//--- Array of types and corresponding info ---//
export const typeButtons = [
  //--- currently unused types ---//
  // ["Accident", "AccidentInfo", "Accident", "Accidente"],
  // ["Damage / Theft", "DamageTheftInfo", "DamageOrTheft", "Robo o Daño"],
  ["Utility Strike", "UtilityStrikeInfo", "UtilityStrike", "Golpe de Servicio"],
  ["PCI Employee Injury", "InjuryInfo", "Injury", "Lesión o Lastimada"],
  ["PCI Personal Conduct / Discipline", "EmployeeInfo", "Employee", "Empleado"],
  [
    "All Other Incidents (use this for anything not listed above, i.e. accidents, equipment damage, etc...)",
    "IncidentInfo",
    "Other",
    "Otro / General"
  ]
]

//--- Customized messages for the camera page with info about restrictions and/or requirements ---//
export const cameraMessages = {
  Injury: [
    "Only include photos of the area where the injury took place. Do not take any photos of the injured employee."
  ],
  Accident: [
    "Be sure to get photos of both the PCI vehicle and the other vehicle involved. Include both close-up and a wide-angle photos of the scene."
  ],
  UtilityStrike: [
    "NOTE: If PCI is NOT at fault for the strike, it is VERY important to document the incident thoroughly as the penalties range anywhere from a few hundred dollars to $25,000+. Please use the following guidelines:",
    "1) Make sure everyone is safe and get a hold of the utility company immediately.",
    "2) Notify Tim (319-404-5334) or Chad (319-242-1214) of the situation.",
    "3) Get several detailed pictures of the strike and surrounding area showing any locates and put something in the pictures to reference the dimensions like a hard hat, caution cone, etc... The more pictures with details the better to defend PCI."
  ],
  DamageOrTheft: [
    "Include photos of the damaged vehicle / equipment or the scene of the theft, as appropriate. Be sure to get both close-up and wide-angle pictures of the area."
  ],
  Employee: [
    "Include any photos relevant to the incident including text messages or other communication. Do not take photos of any person that has suffered an injury. If the incident is an accident, damage or theft, Include photos of the damaged vehicle / equipment or the scene of the theft, as appropriate. Be sure to get both close-up and wide-angle pictures of the area."
  ],
  Other: [
    "Include any photos relevant to the incident. If applicable, place an object in the picture for size reference (i.e. a hard hat, a safety cone, etc...)"
  ]
}

//--- Messages for the summary page ---//
export const summaryTypes = {
  UtilityStrike: "Utility Strike",
  Injury: "Injury",
  Accident: "Accident",
  DamageOrTheft: "Damage / Theft",
  Other: "General Incident",
  Witness: "Witness"
}

//--- Employe injury form text - why did I type all of this :( ---//
export const injuryInfo = `You have allegedly sustained an illness or injury that is work related. \
PCI follows the rules set forth by the State of Iowa regarding injuries or illnesses compensable under the Workers' Compensation System. \
Listed below are items that are the Company's responsibility and items that are your responsibility. \
Read and follow them to ensure smooth processing of your claim. \
Your claim may be denied if you fail to follow these requirements.`

export const injuryInfoSP = `Usted ha supuestamente sufrido una enfermedad o lesión relacionada con el trabajo. \
PCI sigue las reglas establecidas por el Estado de Iowa con respecto a lesiones o enfermedades compensables según el Sistema de Compensación para Trabajadores. \
A continuación se enumeran los elementos que son responsabilidad de la Compañía y los elementos que son su responsabilidad. \
Léalos y sígalos para garantizar un procesamiento fluido de su reclamo. \
Su reclamo puede ser rechazado si no cumple con estos requisitos.`

export const paragraphOne = `Your injury or illness must be directly related to your employment. 
If there is no proof that the injury or illness resulted from your employment, it will be denied under Work Comp. \
Having a pain or becoming ill at work without direct correlation to work is not compensable. \
If your injury or illness is determined to not be work-related, you will be responsible for any and all charges. \
Fraudulent claims will result in termination and possible legal action. \
A fitness for duty evaluation may be required after release to duty from a personal injury/illness.`

export const paragraphOneSP = `Su lesión o enfermedad debe estar directamente relacionada con su empleo.
Si no hay pruebas de que la lesión o enfermedad fue el resultado de su empleo, se le negará según la compensación laboral. \
Tener un dolor o enfermarse en el trabajo sin correlación directa con el trabajo no es compensable. \
Si se determina que su lesión o enfermedad no está relacionada con el trabajo, usted será responsable de todos y cada uno de los cargos. \
Los reclamos fraudulentos resultarán en la terminación y posibles acciones legales. \
Es posible que se requiera una evaluación de aptitud para el servicio después de ser liberado del servicio debido a una lesión o enfermedad personal.`

export const paragraphTwo = `PCI must approve treatment and designates the treating medical provider(s). \
If you choose your own care or seek care without permission from PCI, payments for medical care and wages will be denied. \
On the day of initial approved care, you will be paid your regular wage rate at least through the end of a regular 8-hour shift. \
No wages are paid for follow up care until you have had 3 approved days off without pay.`

export const paragraphTwoSP = `PCI debe aprobar el tratamiento y designar a los proveedores médicos tratantes. \
Si elige su propia atención o busca atención sin el permiso de PCI, se le negarán los pagos por atención médica y salarios. \
El día de la atención inicial aprobada, se le pagará su salario regular al menos hasta el final de un turno regular de 8 horas. \
No se pagan salarios por la atención de seguimiento hasta que haya tenido 3 días libres aprobados sin goce de sueldo.`

export const paragraphThree = `A PCI representative will accompany you to to your appointment or will have made prior arrangements for your treatment. \
You may not change dates, times or treatment schedules without permission of PCI. \
If you fail to follow the treatment regimen as directed, your claim will be suspended and ultimately denied.`

export const paragraphThreeSP = `Un representante de PCI lo acompañará a su cita o habrá hecho arreglos previos para su tratamiento. \
No puede cambiar fechas, horarios ni horarios de tratamiento sin el permiso de PCI. \
Si no sigue el régimen de tratamiento según las indicaciones, su reclamo será suspendido y, en última instancia, denegado.`

export const paragraphFour = `Do not bring relatives to your appointments. \
Mothers, fathers, spouses, etc. are not allowed in the examining or treatment rooms, or to have input into the treatment program.`

export const paragraphFourSP = `No traer familiares a sus citas. \
No se permite a las madres, padres, cónyuges, etc. estar en las salas de examen o tratamiento, ni participar en el programa de tratamiento.`

export const paragraphFive = `You must follow all work restrictions, both at work and at home that are issued by the treating physician. \
It is your responsibility to stay within the restrictions and inform your supervisor of your restrictions if your job assignment is outside of the restrictions.`

export const paragraphFiveSP = `Deberá seguir todas las restricciones laborales, tanto en el trabajo como en el hogar, que sean emitidas por el médico tratante. \
Es su responsabilidad permanecer dentro de las restricciones e informar a su supervisor sobre sus restricciones si su asignación de trabajo está fuera de las restricciones.`

export const paragraphSix = `If the medical provider does not indicate any time off of work (lost time), you must report for your regular shift each day. \
If the medical provider releases you for light duty work and you choose to not accept a light duty assignment, no wage benefit is provided. \
If you do not report for work when released by the medical provider, your absence will be treated accordingly under the attendance policy. \
If the medical provider does not take you off of work, the first 3 days are unpaid and then 66 2/3 percent of your average wage is paid thereafter. \
When released to light duty, you will receive the wage rate of your light duty assignment, plus 66 2/3 percent of the difference between your light duty assignment wage rate and your regular wage rate.`

export const paragraphSixSP = `If the medical provider does not indicate any time off of work (lost time), you must report for your regular shift each day. \
If the medical provider releases you for light duty work and you choose to not accept a light duty assignment, no wage benefit is provided. \
If you do not report for work when released by the medical provider, your absence will be treated accordingly under the attendance policy. \
If the medical provider does not take you off of work, the first 3 days are unpaid and then 66 2/3 percent of your average wage is paid thereafter. \
When released to light duty, you will receive the wage rate of your light duty assignment, plus 66 2/3 percent of the difference between your light duty assignment wage rate and your regular wage rate.`

export const paragraphSeven = `Billings for all injuries or illnesses that are confirmed to be work related will be paid for by PCI and/or PCI's insurance company. \
If you record your odometer readings and miles driven for each appointment, you will be paid for all reasonable transportation caosts, as set by the IRS.`

export const paragraphSevenSP = `PCI y/o la compañía de seguros de PCI pagarán las facturas por todas las lesiones o enfermedades que se confirme que están relacionadas con el trabajo. \
Si registra las lecturas de su odómetro y las millas recorridas para cada cita, se le pagarán todos los problemas de transporte razonables, según lo establecido por el IRS.`

export const paragraphEight = `A drug and alocohol screen is required. \
If either is confirmed positive, any and all billings for services provided by the medical provider(s) will become your responsibility. \
In addition, your employment with Peterson Contractors Inc. will be in jeopardy.`

export const paragraphEightSP = `Se requiere una prueba de detección de drogas y alcohol. \
Si se confirma que cualquiera de los dos es positivo, todas y cada una de las facturaciones de los servicios proporcionados por los proveedores médicos serán su responsabilidad. \
Además, su empleo en Peterson Contractors Inc. estará en peligro.`

export const paragraphNine = `I have read, understand, and have had my questions answered concerning the requirements and penalties listed above. \
I authorize PCI to release my Social Security Number and medical records for insurance or other required purposes.`

export const paragraphNineSP = `He leído, entiendo y he recibido respuestas a mis preguntas sobre los requisitos y sanciones enumerados anteriormente. \
Autorizo a PCI a divulgar mi número de Seguro Social y mis registros médicos para fines de seguro u otros fines requeridos.`
