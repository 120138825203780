import { useState } from "react"
import { formatPhone } from "../Utils/HelperFunctions"

export default function NonEmployeeInfo({ info, setPhone, setEmail, spanish }) {
  const [PCIEmployee, setPCIEmployee] = useState(true)
  return (
    <>
      <label htmlFor="employeeYN">{spanish ? "Soy empleado de PCI" : "I am a PCI employee"}</label>
      <div id="employeeYN" className="radio-container">
        <label htmlFor="PCIYes">
          {spanish ? "Sí" : "YES"}
          <input type="radio" id="PCIYes" checked={PCIEmployee} onChange={() => setPCIEmployee((curr) => !curr)} />
        </label>
        <label htmlFor="PCINo">
          No
          <input type="radio" id="PCINo" checked={!PCIEmployee} onChange={() => setPCIEmployee((curr) => !curr)} />
        </label>
      </div>
      {!PCIEmployee && (
        <>
          <label htmlFor="phone">Your phone number</label>
          <input
            type="text"
            id="phone"
            required={true}
            value={formatPhone(info.PhoneNumber)}
            onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
          />
          <label htmlFor="email">Your email address</label>
          <input
            type="text"
            id="email"
            required={true}
            value={info.EmailAddress}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
      )}
    </>
  )
}
