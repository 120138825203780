import { PersonModel } from "./PersonModel"

export class PoliceInfoModel {
  constructor() {
    this.RespondingDepartment = ""
    this.ReportNumber = ""
    this.PhotosOfCasePapers = []
    this.PrimaryOfficer = new PersonModel()
    this.CitationNumber = ""
    this.CitationRecipient = new PersonModel()
    this.CitationReason = ""
  }
}
