import { useState } from "react"
import { Navigate } from "react-router-dom"
import { typeButtons } from "../Utils/Constants"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import ExistingReportForm from "../Components/ExistingReportForm"
import TypeButton from "../Components/TypeButton"
import "../Styles/App.css"
import "../Styles/Home.css"

export default function Home({ update, create, spanish, fetchReport, unfinished, continuing }) {
  //--- State variables ---//
  const [view, setView] = useState({ new: false, existing: false })

  //--- Logic for toggling menus ---//
  const handleTypeClick = (e) => {
    switch (e.target.id) {
      case "new-btn":
        if (!view.new && !view.existing) setView({ new: true, existing: false })
        else if (view.new && !view.existing) setView({ new: false, existing: false })
        else setView({ new: true, existing: false })
        break
      case "existing-btn":
        if (!view.new && !view.existing) setView({ new: false, existing: true })
        else if (view.existing && !view.new) setView({ new: false, existing: false })
        else setView({ new: false, existing: true })
        break
      default:
        return
    }
  }

  return (
    <div className="home-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>{spanish ? "PCI Portal de Incidentes" : "PCI Incident Portal"}</h2>
      <div className="home-decision">
        <div className="new-report-types">
          <button id="new-btn" className="new-existing-btn" onClick={handleTypeClick}>
            {spanish ? "Reportaje Nuevo" : "Begin New Report"}
          </button>
          <div
            className="type-button-container"
            style={view.new ? { visibility: "visible" } : { visibility: "hidden" }}
          >
            {/* Renders type buttons */}
            {typeButtons.map(([name, route, type, spanishText], i) => (
              <TypeButton
                create={create}
                type={type}
                text={name}
                route={route}
                key={i}
                spanish={spanish}
                spanishText={spanishText}
              />
            ))}
          </div>
        </div>
        <div className="existing-report">
          <button
            id="unfinished-button"
            className="existing-btn"
            style={{ minHeight: "50px", marginBottom: "5%" }}
            onClick={unfinished}
            disabled={continuing.startsWith("No")}
          >
            {continuing}
          </button>
          <div className="new-report-types">
            <button id="existing-btn" style={{ minHeight: "50px" }} className="existing-btn" onClick={handleTypeClick}>
              {spanish ? "Tengo código de acceso" : "Enter code"}
            </button>
            <div className="type-button-container" style={{ visibility: view.existing ? "visible" : "hidden" }}>
              <ExistingReportForm update={update} spanish={spanish} fetchReport={fetchReport} />
            </div>
          </div>
        </div>
      </div>
      <h4 style={{ borderBottom: "1px solid black" }}>PCI Safety Team</h4>
      <h6>Kelsey Irvin: 319-788-4782</h6>
      <h6>Chad Frahm 319-242-1214</h6>
      <h6>Tim Timmer 319-404-5334</h6>
      <h6>Mike Bell 319-560-8565</h6>
    </div>
  )
}
