import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import GPSHelper from "../Components/GPSHelper"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function IncidentDetails({ report, update }) {
  //--- State variables ---//
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [GPS, setGPS] = useState(!!detailsInfo.IncidentReportLatitude || !!detailsInfo.IncidentReportLongitude)
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()
  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Sets Gps information ---//
  const handleAddGps = ({ latitude, longitude }) => {
    setDetailsInfo((curr) => ({
      ...curr,
      IncidentReportLatitude: (latitude && latitude.toFixed(5)) || "",
      IncidentReportLongitude: (longitude && longitude.toFixed(5)) || ""
    }))
  }

  //--- Saves progress and navigates to witness information page ---//
  const handleDetailsInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(detailsInfo)
    setSaving(false)
    navigate("/Witnesses")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Incident Details</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="descriptionText">Describe the incident in detail:</label>
        <textarea
          rows="16"
          id="descriptionText"
          placeholder="Be as detailed as possible!"
          required
          maxLength="2048"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="locationText">Describe the location of the incident in detail:</label>
        <textarea
          rows="3"
          id="locationText"
          placeholder="Be as detailed as possible!"
          required
          maxLength="1024"
          value={detailsInfo.IncidentLocationDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentLocationDescription: e.target.value
            }))
          }
        />
        <label htmlFor="gpsLocation">Are you currently at the location of the incident?</label>
        <div id="gpsLocation" className="radio-container">
          <label htmlFor="gpsYes">
            YES
            <input type="radio" id="gpsYes" name="gps" checked={GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
          <label htmlFor="gpsNo">
            NO
            <input type="radio" id="gpsNo" name="gps" checked={!GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
        </div>
        <div className={GPS ? "optional-field-visible" : "optional-field-hidden"}>
          {GPS && <GPSHelper active={GPS} addLocation={handleAddGps} />}
        </div>
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/IncidentInfo")}>
            Go Back
          </button>
          <input type="submit" className="save-back-button" value={saving ? "Saving..." : "Save and Continue"} />
        </div>
      </form>
    </div>
  )
}
