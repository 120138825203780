import { useState } from "react"
import "../Styles/GetReportForm.css"
import { useNavigate } from "react-router-dom"

export default function ExistingReportForm({ update, spanish, fetchReport }) {
  const [accessCode, setAccessCode] = useState("")
  const [message, setMessage] = useState("")

  const navigate = useNavigate()

  async function handleGetReportSubmit(e) {
    e.preventDefault()
    if (!accessCode.trim()) return
    try {
      const response = await fetchReport(`${accessCode}`)
      if (response.data) {
        update(response.data)
        navigate(
          `/${response.data.ReportType === "Other" ? "Incident" : response.data.ReportType.replace("Report", "")}Info`
        )
      }
      setAccessCode("")
    } catch (e) {
      console.error(e.message)
      setMessage(spanish ? "Código invalido" : "Invalid Access code")
      setTimeout(() => {
        setMessage("")
      }, 1000)
    }
  }

  return (
    <div className="get-report-container">
      <form onSubmit={handleGetReportSubmit}>
        <label htmlFor="reportId">{spanish ? "Código de aceso" : "Access Code"}</label>
        <input
          id="reportId"
          type="text"
          value={accessCode}
          onChange={(e) => {
            setMessage("")
            setAccessCode(e.target.value)
          }}
        />
        <input type="submit" value={spanish ? "Buscar reportaje" : "Fetch Report"} />
      </form>
      <p>{message}</p>
    </div>
  )
}
