import { useEffect, useState } from "react"
import { useNavigate, Navigate } from "react-router"
import { ppeList } from "../Utils/Constants"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import PPE from "../Components/PPE"
import "../Styles/PPE.css"

export default function PPEUsed({ report, update, NA, setNA }) {
  //--- State variables ---//
  const [PPEInfos, setPPEUsed] = useState(report.PPEInfos || [])
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Adds ppe to array ---//
  const handleAddPPE = (ppe) => {
    setPPEUsed((curr) => [...curr, ppe])
  }

  //--- Edits selected ppe ---//
  const handleEditPPE = (comment, ppe) => {
    const selected = PPEInfos.find((e) => e.Type === ppe)
    selected.Comments = comment
    setPPEUsed((curr) => [...curr.filter((e) => e.Type !== ppe), selected])
  }

  //--- Removes ppe from array ---//
  const handleDeletePPE = (type) => {
    setPPEUsed((curr) => curr.filter((ppe) => ppe.Type !== type))
  }

  //--- Edits boolean on ppe to/from missing/used improperly
  const handleToggle = (ppe, prop) => {
    const selected = PPEInfos.find((e) => e.Type === ppe)
    if (prop === "MissingFromPerson") {
      selected.MissingFromPerson = true
      selected.UsedImproperly = false
    } else {
      selected.MissingFromPerson = false
      selected.UsedImproperly = true
    }
    setPPEUsed((curr) => [...curr.filter((e) => e.Type !== ppe), selected])
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <span>
        <h2>PPE (personal protective equipment)</h2>
      </span>
      <label htmlFor="ppeNA" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Was any PPE (personal protective equipment) missing or used improperly?
      </label>
      <div id="ppeNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>
      {!NA && (
        <>
          <h4>Check all that apply and mark as "missing" or "used improperly"</h4>
          {/* <PPEForm add={handleAddPPE} /> */}
          <div className="ppe-container">
            {ppeList.map((ppe) => {
              return (
                <PPE
                  ppe={ppe}
                  check={PPEInfos.some((e) => e.Type === ppe)}
                  com={(PPEInfos.find((e) => e.Type === ppe) || { Comments: "" }).Comments}
                  missing={(PPEInfos.find((e) => e.Type === ppe) || { MissingFromPerson: false }).MissingFromPerson}
                  improper={(PPEInfos.find((e) => e.Type === ppe) || { UsedImproperly: false }).UsedImproperly}
                  add={handleAddPPE}
                  remove={handleDeletePPE}
                  edit={handleEditPPE}
                  toggle={handleToggle}
                  key={crypto.randomUUID()}
                />
              )
            })}
            {/* {PPEInfos.map((ppe) => (
              <PPE ppe={ppe} edit={handleEditPPE} remove={handleDeletePPE} key={crypto.randomUUID()} />
            ))} */}
          </div>
        </>
      )}
      <div className="save-back-button-container">
        <button className="save-back-button" onClick={() => navigate("/HospitalInfo")}>
          Go Back
        </button>
        <button
          className="save-back-button"
          onClick={async () => {
            if (PPEInfos.some((e) => e.Type === "Other" && e.Comments === "")) {
              alert('An explanation is required for the "Other" option')
              window.scrollTo({ top: 600, left: 0, behavior: "smooth" })
              return
            }
            await update(PPEInfos)
            navigate("/AdditionalInfo")
          }}
        >
          Save and Continue
        </button>
      </div>
    </div>
  )
}
