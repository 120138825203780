import { Link } from "react-router-dom"

import "../Styles/TypeButton.css"
import "../Styles/App.css"

export default function TypeButton({ type, text, route, create, spanish, spanishText }) {
  return (
    <div>
      <Link to={route} className="type-button" onClick={() => create(type)}>
        {spanish ? spanishText : text}
      </Link>
    </div>
  )
}
