//--- This a dead feature. Leaving it in in the event we decide to implement it later on. ---//

export default function MapViewer() {
  const coords = { lat: "42.32495", lon: "-92.59903" }

  return (
    <div style={{ marginTop: "3em" }}>
      <iframe
        title="location picker"
        src={`https://maps.google.com/maps?q=${coords.lat},${coords.lon}&hl=es;&output=embed`}
        height="50%"
      />
    </div>
  )
}
