import { useEffect, useState } from "react"

export default function DatePicker({ info, set, spanish }) {
  const [date, setDate] = useState(
    info.IncidentDatetime ? new Date(info.IncidentDatetime).toISOString().split("T")[0] : null
  )
  const [time, setTime] = useState(
    info.IncidentDatetime ? new Date(info.IncidentDatetime).toTimeString().slice(0, 5) : null
  )

  useEffect(() => {
    const updateDatetime = () => {
      if (!date || !time) return
      const newDate = new Date(date + "T" + time)
      newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60)
      set((curr) => ({
        ...curr,
        IncidentDatetime: newDate
      }))
    }
    updateDatetime()
  }, [date, time, set])

  return (
    <>
      <h5>When did the incident occur?</h5>
      <div className="radio-container">
        <div>
          <label htmlFor="reportDate">{spanish ? "Fecha del Incidente" : "Incident date"}</label>
          <input
            type="date"
            id="reportDate"
            value={date}
            required
            onChange={(e) => setDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div>
          <label htmlFor="reportTime">{spanish ? "Tiempo del Incidente" : "Time of incident"}</label>
          <input type="time" id="reportTime" value={time} required onChange={(e) => setTime(e.target.value)} />
        </div>
      </div>
    </>
  )
}
