import { PersonModel } from "./PersonModel"

export class HospitalModel {
  constructor() {
    this.HospitalName = ""
    this.City = ""
    this.State = ""
    this.Zip = ""
    this.Doctor = new PersonModel()
    this.Restrictions = ""
    this.hasRestrictions = false
    this.NeedsHospitalVisitLater = false
    this.EmployeeDrugTested = false
    this.NoDrugTestComments = ""
    this.SafetyTeamMemberContacted = new PersonModel()
    this.FollowUpDate = new Date()
  }
}
