import { useNavigate } from "react-router-dom"

export default function Error({ reset, NASetters, report }) {
  const navigate = useNavigate()

  if (report) {
    window.history.back()
  }

  return (
    <div className="general-info-container">
      <h1>OOPS!</h1>
      <h5>Something went wrong. Contact the Safety team at PCI if the problem persists.</h5>
      <div className="save-back-button-container">
        <button
          className="save-back-button"
          onClick={() => {
            reset({ ReportType: "None" })
            NASetters.forEach((setter) => setter(false))
            navigate("/")
          }}
        >
          HOME
        </button>
      </div>
    </div>
  )
}
