import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { pagesController } from "./Utils/Constants.js"
import { loginRequest } from "./Utils/msalConfig.js"
import { useMsal } from "@azure/msal-react"
import axios from "axios"
import Home from "./Pages/Home"
import AccidentInfo from "./Pages/AccidentInfo.jsx"
import Witnesses from "./Pages/Witnesses"
import Photos from "./Pages/Photos"
import Police from "./Pages/Police"
import NavigationTab from "./Components/NavigationTab"
import AccidentDetails from "./Pages/AccidentDetails"
import InjuryInfo from "./Pages/InjuryInfo"
import InjuryDetails from "./Pages/InjuryDetails"
import UtilityInfo from "./Pages/UtilityInfo"
import UtilityDetails from "./Pages/UtilityDetails"
import IncidentInfo from "./Pages/IncidentInfo"
import IncidentDetails from "./Pages/IncidentDetails"
import DamageTheftInfo from "./Pages/DamageTheftInfo"
import DamageTheftDetails from "./Pages/DamageTheftDetails"
import PPEUsed from "./Pages/PPEList.jsx"
import Hospital from "./Pages/Hospital"
import ReportSummary from "./Pages/ReportSummary.jsx"
import AdditionalInfo from "./Pages/AdditionalInfo.jsx"
import AutoScroller from "./Components/AutoScroller.jsx"
import MapViewer from "./Components/MapViewer.jsx"
import InjuredEmployeeInfo from "./Pages/InjuredEmployeeInfo.jsx"
import Confirmation from "./Pages/Confirmation.jsx"
import WitnessInfo from "./Pages/WitnessInfo.jsx"
import WitnessDetails from "./Pages/WitnessDetails.jsx"
import Error from "./Pages/Error.jsx"
import EmployeeInfo from "./Pages/EmployeeInfo.jsx"
import EmployeeDetails from "./Pages/EmployeeDetails.jsx"
import Loader from "./Components/Loader.jsx"
import Login from "./Pages/Login.jsx"
import "./Styles/App.css"

export const BASEURL = process.env.REACT_APP_BASE_SERVICE_URL
export const debug = process.env.REACT_APP_BASE_SERVICE_URL.includes("test")

export default function App() {
  //--- Global state variables and setters ---//
  const [report, setReport] = useState({ ReportType: "None" })
  const [safetyTeam, setSafetyTeam] = useState([])
  const [spanish, setSpanish] = useState(false)
  const [witnessesNA, setWitnessesNA] = useState(false)
  const [cameraNA, setCameraNA] = useState(false)
  const [policeNA, setPoliceNA] = useState(false)
  const [hospitalNA, setHospitalNA] = useState(false)
  const [ppeNA, setPpeNA] = useState(false)
  const [injuredEmployeeNA, setInjuredEmployeeNA] = useState(false)
  const [additionalInfoNA, setAdditionalInfoNA] = useState(false)
  const [user, setUser] = useState(null)
  const [continueMessage, setContinueMessage] = useState("Continue an Unfinished Report")
  const [retrieving, setRetrieving] = useState(false)
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  if (debug) {
    console.log("CACHED: ", localStorage.getItem("reportUUID"))
    console.log("ROOT REPORT: ", report)
  }

  //--- Global save progress functions ---//
  const acquireToken = async () => {
    if (!account) return
    const response = await instance.acquireTokenSilent({ ...loginRequest, account: account })
    return response.accessToken
  }

  const createReport = async (type) => {
    const token = await acquireToken()
    const response = await axios
      .post("api/new", { ReportType: type }, { headers: { ...axios.headers, Authorization: `Bearer ${token}` } })
      .catch((err) => {
        console.error(err.message)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport(response.data)
  }

  const getWitnessToken = async (id) => {
    const response = await axios.post("api/token", { code: id }).catch((err) => {
      console.error(err)
      window.location.replace(window.location.origin + "/Error")
      return
    })
    return response.data
  }

  const fetchReport = async (token) => {
    const response = await axios
      .get(`api/resume`, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
      })
    return response
  }

  const updateGeneralInfo = async (generalInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/general/${report.UUID}`, generalInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, GeneralInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updateWitnessGeneralInfo = async (witnessGeneralInfo, token) => {
    const response = await axios
      .post("api/witnessgeneral", witnessGeneralInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, GeneralInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updateDetailedInfo = async (detailedInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/details/${report.UUID}`, detailedInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, DetailedInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updateWitnessDetailedInfo = async (witnessDetailedInfo, token) => {
    const response = await axios
      .post("api/witnessdetails", witnessDetailedInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, DetailedInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updateInjuryAcknowledgementInfo = async (acknowledgementInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/injuryinfo/${report.UUID}`, acknowledgementInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({
      ...curr,
      InjuryAcknowledgementInfo: response.data
    }))
    return new Promise((res) => res(true))
  }

  const updateWitnesses = async (witnesses) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/witnesses/${report.UUID}`, witnesses, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, WitnessInfos: response.data }))
    return new Promise((res) => res(true))
  }

  const updatePhotos = async (photos) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/photos/${report.UUID}`, photos, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, Photos: response.data }))
    return new Promise((res) => res(true))
  }

  const updatePoliceInfo = async (policeInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/police/${report.UUID}`, policeInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, PoliceInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updateHospitalInfo = async (hospitalInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/hospital/${report.UUID}`, hospitalInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, HospitalInfo: response.data }))
    return new Promise((res) => res(true))
  }

  const updatePPE = async (ppeInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(`api/ppe/${report.UUID}`, ppeInfo, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, PPEInfos: response.data }))
    return new Promise((res) => res(true))
  }

  const updateAdditionalInfo = async (additionalInfo) => {
    const token = await acquireToken()
    const response = await axios
      .post(
        `api/additional/${report.UUID}`,
        { additionalInfo },
        { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
      )
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, AdditionalInformation: response.data }))
    return new Promise((res) => res(true))
  }

  const updateWitnessAdditionalInfo = async (witnessAdditionalInfo, token) => {
    const response = await axios
      .post(
        "api/witnessadditional",
        { witnessAdditionalInfo },
        { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
      )
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    setReport((curr) => ({ ...curr, AdditionalInformation: response.data }))
    return new Promise((res) => res(true))
  }

  const completeReport = async () => {
    const token = await acquireToken()
    const response = await axios
      .post(
        `api/complete/${report.UUID}`,
        {},
        {
          headers: { ...axios.headers, Authorization: `Bearer ${token}` }
        }
      )
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    localStorage.clear()
    return response.data
  }

  const completeWitnessReport = async (token) => {
    const response = await axios
      .post(
        "api/witnesscomplete",
        {},
        {
          headers: { ...axios.headers, Authorization: `Bearer ${token}` }
        }
      )
      .catch((err) => {
        console.error(err)
        window.location.replace(window.location.origin + "/Error")
        return
      })
    localStorage.clear()
    return response.data
  }

  const getSafetyTeam = async () => {
    const token = await acquireToken()
    if (!token) return
    const team = await axios.get("api/safety", { headers: { ...axios.headers, Authorization: `Bearer ${token}` } })
    setSafetyTeam([...team.data, "None"])
  }

  const handleRetrieveReport = async () => {
    const token = await acquireToken()
    if (!token) return
    const savedReport = await axios.get(`api/getreport/${localStorage.getItem("reportUUID")}`, {
      headers: { ...axios.headers, Authorization: `Bearer ${token}` }
    })
    setReport(savedReport.data)
  }

  const handleCheckUnfinishedReport = async () => {
    console.log("CHECKING FOR REPORT...")
    const token = await acquireToken()
    if (!token) return
    setRetrieving(true)
    const res = await axios
      .get(`api/getunfinishedreport`, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => {
        console.error(err)
        setContinueMessage("No Report Found")
        setRetrieving(false)
        return
      })
    if (res && res.status === 200) {
      setReport(res.data)
      window.location.href = `${window.location.origin}/ReportSummary`
    }
    setRetrieving(false)
  }

  useEffect(() => {
    console.log("EFFECT: EMPTY")
    getSafetyTeam()
    if (report.ReportType === "None" && "reportUUID" in localStorage) {
      handleRetrieveReport()
    }
  }, [])

  useEffect(() => {
    console.log("EFFECT: REPORT", report)
    if ("UUID" in report && !localStorage.getItem("reportUUID")) {
      localStorage.setItem("reportUUID", report.UUID)
    }
  }, [report])

  return (
    <BrowserRouter>
      <AutoScroller />
      <div className="main-container">
        <header className="header">
          <a href="https://pcius.com" target="blank">
            <div className="PCIImage"></div>
          </a>
          {(window.location.pathname === "/" ||
            window.location.pathname === "" ||
            window.location.pathname === "/Login") && (
            <button className="spanish-button" onClick={() => setSpanish((curr) => !curr)}>
              {spanish ? "Inglés" : "Spanish"}
            </button>
          )}
          {account && (
            <h6>
              {(spanish && "Hola, ") || "Hi, "}
              {account.name}!
            </h6>
          )}
        </header>
        <div className="pages-container">
          <Routes>
            <Route path="/Login" element={<Login set={setUser} spanish={spanish} />} />
            <Route
              path="/"
              element={
                <Home
                  report={report}
                  create={createReport}
                  spanish={spanish}
                  update={setReport}
                  user={user}
                  fetchReport={fetchReport}
                  unfinished={handleCheckUnfinishedReport}
                  continuing={continueMessage}
                />
              }
            />
            <Route
              path="/UtilityStrikeInfo"
              element={<UtilityInfo report={report} update={updateGeneralInfo} safetyTeam={safetyTeam} user={user} />}
            />
            <Route
              path="/UtilityStrikeDetails"
              element={<UtilityDetails report={report} update={updateDetailedInfo} user={user} />}
            />
            <Route
              path="/InjuryInfo"
              element={
                <InjuryInfo
                  report={report}
                  update={updateGeneralInfo}
                  safetyTeam={safetyTeam}
                  spanish={spanish}
                  user={user}
                />
              }
            />
            <Route
              path="/InjuryDetails"
              element={<InjuryDetails report={report} update={updateDetailedInfo} spanish={spanish} user={user} />}
            />
            <Route
              path="/EmployeeInfo"
              element={
                <EmployeeInfo
                  report={report}
                  update={updateGeneralInfo}
                  safetyTeam={safetyTeam}
                  spanish={spanish}
                  user={user}
                />
              }
            />
            <Route
              path="/EmployeeDetails"
              element={<EmployeeDetails report={report} update={updateDetailedInfo} spanish={spanish} user={user} />}
            />
            <Route
              path="/AccidentInfo/*"
              element={
                <AccidentInfo
                  report={report}
                  update={updateGeneralInfo}
                  safetyTeam={safetyTeam}
                  spanish={spanish}
                  user={user}
                />
              }
            />
            <Route
              path="/AccidentDetails"
              element={<AccidentDetails report={report} update={updateDetailedInfo} user={user} />}
            />
            <Route
              path="/DamageTheftInfo"
              s
              element={
                <DamageTheftInfo report={report} update={updateGeneralInfo} safetyTeam={safetyTeam} user={user} />
              }
            />
            <Route
              path="/DamageTheftDetails"
              element={<DamageTheftDetails report={report} update={updateDetailedInfo} user={user} />}
            />
            <Route
              path="/IncidentInfo"
              element={<IncidentInfo report={report} update={updateGeneralInfo} safetyTeam={safetyTeam} user={user} />}
            />
            <Route
              path="/IncidentDetails"
              element={<IncidentDetails report={report} update={updateDetailedInfo} user={user} />}
            />
            <Route
              path="/Witnesses"
              element={
                <Witnesses
                  report={report}
                  update={updateWitnesses}
                  NA={witnessesNA}
                  setNA={setWitnessesNA}
                  user={user}
                />
              }
            />
            <Route
              path="/Camera"
              element={<Photos report={report} update={updatePhotos} NA={cameraNA} setNA={setCameraNA} user={user} />}
            />
            <Route
              path="/PoliceInfo"
              element={
                <Police
                  report={report}
                  update={updatePoliceInfo}
                  spanish={spanish}
                  NA={policeNA}
                  setNA={setPoliceNA}
                  user={user}
                />
              }
            />
            <Route
              path="/PPE"
              element={<PPEUsed report={report} update={updatePPE} NA={ppeNA} setNA={setPpeNA} user={user} />}
            />
            <Route
              path="/InjuredEmployeeInfo"
              element={
                <InjuredEmployeeInfo
                  report={report}
                  update={updateInjuryAcknowledgementInfo}
                  NA={injuredEmployeeNA}
                  setNA={setInjuredEmployeeNA}
                  spanish={spanish}
                  user={user}
                />
              }
            />
            <Route
              path="/HospitalInfo"
              element={
                <Hospital
                  report={report}
                  update={updateHospitalInfo}
                  NA={hospitalNA}
                  safetyTeam={safetyTeam}
                  setNA={setHospitalNA}
                  user={user}
                />
              }
            />
            <Route path="/LocationInfo" element={<MapViewer report={report} />} />
            <Route
              path="/AdditionalInfo"
              element={
                <AdditionalInfo
                  report={report}
                  update={updateAdditionalInfo}
                  updateWitness={updateWitnessAdditionalInfo}
                  NA={additionalInfoNA}
                  setNA={setAdditionalInfoNA}
                  user={user}
                />
              }
            />
            <Route
              path="/WitnessInfo"
              element={<WitnessInfo report={report} update={updateWitnessGeneralInfo} user={user} />}
            />
            <Route
              path="/WitnessDetails"
              element={<WitnessDetails report={report} update={updateWitnessDetailedInfo} user={user} />}
            />
            <Route
              path="/ReportSummary"
              element={
                <ReportSummary
                  report={report}
                  complete={completeReport}
                  completeWitness={completeWitnessReport}
                  reset={setReport}
                  NASetters={[
                    setWitnessesNA,
                    setCameraNA,
                    setPoliceNA,
                    setHospitalNA,
                    setPpeNA,
                    setInjuredEmployeeNA,
                    setAdditionalInfoNA
                  ]}
                  user={user}
                  setUser={setUser}
                  retrieving={retrieving}
                />
              }
            />
            <Route path="/Confirmation" element={<Confirmation user={user} />} />
            <Route
              path="/:id"
              element={
                <Loader
                  update={setReport}
                  report={report}
                  setUser={setUser}
                  getWitnessToken={getWitnessToken}
                  fetchReport={fetchReport}
                />
              }
            />
            <Route
              path="/Error"
              element={
                <Error
                  reset={setReport}
                  NASetters={[
                    setWitnessesNA,
                    setCameraNA,
                    setPoliceNA,
                    setHospitalNA,
                    setPpeNA,
                    setInjuredEmployeeNA,
                    setAdditionalInfoNA
                  ]}
                  report={report.ReportType !== "None"}
                />
              }
            />
          </Routes>
        </div>
        <footer className="footer">
          <h5>Use these buttons to jump to a specific section of the report</h5>
          <nav className="nav-bar">
            {(pagesController[report.ReportType] || []).map(({ name, spanishText, route }) => {
              //--- controller for whether the tab is completed or set to N/A ---//
              let completed = false
              switch (name) {
                case "Info":
                  completed = report.GeneralInfo !== null
                  break
                case "Details":
                  completed = report.DetailedInfo !== null
                  break
                case "Witnesses":
                  completed = witnessesNA || report.WitnessInfos?.length
                  break
                case "Camera":
                  completed = cameraNA || report.Photos?.length
                  break
                case "Police":
                  completed = policeNA || report.PoliceInfo
                  break
                case "Hospital":
                  completed = hospitalNA || report.HospitalInfo
                  break
                case "PPE":
                  completed = ppeNA || report.PPEInfos?.length
                  break
                case "Info form":
                  completed = injuredEmployeeNA || report.InjuryAcknowledgementInfo
                  break
                case "Adt'l Info":
                  completed = additionalInfoNA || report.AdditionalInformation
                  break
                default:
                  completed = true
                  break
              }
              return (
                <NavigationTab
                  name={name}
                  spanish={spanish}
                  spanishText={spanishText}
                  route={route}
                  key={crypto.randomUUID()}
                  completed={completed}
                />
              )
            })}
          </nav>
        </footer>
      </div>
    </BrowserRouter>
  )
}
