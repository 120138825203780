import { PersonModel } from "./PersonModel"

export class GeneralInfoModel {
  constructor() {
    this.IncidentDatetime = null
    this.Reporter = new PersonModel()
    this.Supervisor = new PersonModel()
    this.JobNumber = ""
    this.AffectedEmployee = new PersonModel()
    this.AffectedEmployees = []
    this.EmployeeDuties = ""
    this.VehicleNumber = ""
    this.EquipmentNumber = ""
    this.SupervisorReportedTo = new PersonModel()
  }
}
