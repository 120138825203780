import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import GPSHelper from "../Components/GPSHelper"

export default function InjuryDetails({ report, update }) {
  //--- State variables ---//
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [preventable, setPreventable] = useState(true)
  const [GPS, setGPS] = useState(!!detailsInfo.IncidentReportLatitude || !!detailsInfo.IncidentReportLongitude)
  const [saving, setSaving] = useState(false)

  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/")
  })

  //--- Sets Gps info ---//
  const handleAddGps = ({ latitude, longitude }) => {
    setDetailsInfo((curr) => ({
      ...curr,
      IncidentReportLatitude: (latitude && latitude.toFixed(5)) || "",
      IncidentReportLongitude: (longitude && longitude.toFixed(5)) || ""
    }))
  }

  //--- save progress and navigate to injury information page ---//
  const handleDetailsInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(detailsInfo)
    setSaving(false)
    navigate("/InjuredEmployeeInfo")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Injury Details</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="reportDeatiledIinfo">Describe the injury in detail:</label>
        <textarea
          id="reportDeatiledIinfo"
          rows="16"
          placeholder="Be specific. What body parts were affected and how? (E.g. right leg broken bone, left arm cut, etc...)"
          required
          maxLength="2048"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="reportCauseInfo">What was the cause of/actions that led to the injury?</label>
        <textarea
          id="reportCauseInfo"
          rows={8}
          placeholder="How did the injury happen? What was the cause?"
          required
          maxLength="1024"
          value={detailsInfo.IncidentCause}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentCause: e.target.value
            }))
          }
        />
        <label htmlFor="locationDescription">
          Describe the location of the injury and what the employee was doing prior to the injury:
        </label>
        <textarea
          id="locationDescription"
          rows="3"
          placeholder="Where on the jobsite did the injury happen? Where was the employee at the time of the injury? What was the employee doing prior to the injury? (operating equipment, cutting rebar, walking to the job trailer, etc...)"
          required
          maxLength="1024"
          value={detailsInfo.IncidentLocationDescription}
          onChange={(e) => {
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentLocationDescription: e.target.value
            }))
          }}
        />
        <label htmlFor="preventable">Could this injury have been prevented?</label>
        <div id="preventable" className="radio-container">
          <label htmlFor="preventableY">
            YES
            <input
              type="radio"
              id="preventableY"
              name="preventable"
              checked={preventable || detailsInfo.PossiblePreventionActions.length > 0}
              onChange={() => setPreventable((curr) => true)}
            />
          </label>
          <label htmlFor="preventableN">
            NO
            <input
              type="radio"
              id="preventableN"
              name="preventable"
              checked={!preventable}
              onChange={() => setPreventable((curr) => !curr)}
            />
          </label>
        </div>
        <div className={preventable ? "optional-field-visible" : "optional-field-hidden"}>
          <label htmlFor="preventionInfo">What could have been done to prevent this injury?</label>
          <textarea
            id="preventionInfo"
            className="description-textarea"
            rows={6}
            placeholder="Could you or anyone else have done something to prevent the injury?"
            required={preventable}
            maxLength="1024"
            value={detailsInfo.PossiblePreventionActions}
            onChange={(e) =>
              setDetailsInfo((curr) => ({
                ...curr,
                PossiblePreventionActions: e.target.value
              }))
            }
          />
        </div>
        <label htmlFor="gpsLocation">Are you currently at the location of the injury?</label>
        <div id="gpsLocation" className="radio-container">
          <label htmlFor="gpsYes">
            YES
            <input type="radio" id="gpsYes" name="gps" checked={GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
          <label htmlFor="gpsNo">
            NO
            <input type="radio" id="gpsNo" name="gps" checked={!GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
        </div>
        <div className={GPS ? "optional-field-visible" : "optional-field-hidden"}>
          {GPS && <GPSHelper active={GPS} addLocation={handleAddGps} />}
        </div>
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/InjuryInfo")}>
            Go Back
          </button>
          <input className="save-back-button" type="submit" value={saving ? "Saving..." : "Save and continue"} />
        </div>
      </form>
    </div>
  )
}
