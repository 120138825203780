import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import { jobify } from "../Utils/HelperFunctions"
import DatePicker from "../Components/DatePicker"
import Spinner from "../Components/Spinner"

export default function InjuryInfo({ report, update }) {
  //--- State variables ---//
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())
  const [saving, setSaving] = useState(false)

  const navigate = useNavigate()
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report) navigate("/Error")
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  })

  //--- Saves progress and navigates to deatils page ---//
  const handleGeneralInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(generalInfo)
    setSaving(false)
    navigate("/InjuryDetails")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      {(report.ReportType !== "None" && (
        <>
          <h2>PCI Empoyee Injury Information</h2>
          <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
            <DatePicker info={generalInfo} set={setGeneralInfo} />
            <label htmlFor="name">Name of the person completing this report:</label>
            <input
              type="text"
              id="name"
              required
              maxLength="35"
              placeholder="Your name"
              value={generalInfo.Reporter.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee # of person completing this report:</label>
            <input
              type="text"
              id="employeeNumber"
              required
              maxLength="4"
              placeholder="Your employee #"
              value={generalInfo.Reporter.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, EmployeeNumber: e.target.value }
                }))
              }
            />
            <label htmlFor="jobNumber">Job number where the employee was injured:</label>
            <input
              type="text"
              id="jobNumber"
              required
              maxLength="7"
              placeholder="Job where the injury occurred"
              value={generalInfo.JobNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  JobNumber: jobify(e.target.value)
                }))
              }
            />
            <label htmlFor="supervisor">Supervisor at jobsite:</label>
            <input
              type="text"
              id="supervisor"
              required
              maxLength="35"
              placeholder="Supervisor's name"
              value={generalInfo.Supervisor.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Supervisor: { ...curr.Supervisor, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeAffected">Name of the injured employee:</label>
            <input
              type="text"
              id="employeeAffected"
              required
              maxLength="35"
              placeholder="Injured employee's name"
              value={generalInfo.AffectedEmployee.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Name: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedNumber">Employee # of the injured employee:</label>
            <input
              type="text"
              id="employeeAffectedNumber"
              maxLength="4"
              placeholder="Injured employee's #"
              value={generalInfo.AffectedEmployee.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    EmployeeNumber: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="safetyTeamInput">Person the injury was reported to:</label>
            <input
              id="safetyTeamInput"
              type="text"
              maxLength="35"
              value={generalInfo.SupervisorReportedTo?.Name}
              placeholder="Enter name"
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  SupervisorReportedTo: {
                    ...curr.SupervisorReportedTo,
                    Name: e.target.value
                  }
                }))
              }
            />
            <div className="save-back-button-container">
              <input className="save-back-button" type="submit" value={saving ? "Saving..." : "Save and continue"} />
            </div>
          </form>
        </>
      )) || <Spinner />}
    </div>
  )
}
