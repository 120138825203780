import { ViolationsModel } from "./ViolationsModel"

export class DetailedInfoModel {
  constructor() {
    this.LocationAddress = ""
    this.IncidentLocationDescription = ""
    this.IncidentReportLatitude = ""
    this.IncidentReportLongitude = ""
    this.IncidentDescription = ""
    this.IncidentCause = ""
    this.PossiblePreventionActions = ""
    this.PciAtFault = false
    this.PartyAtFault = ""
    this.EmployeeDrugTested = false
    this.NoDrugTestComments = ""
    this.WarningIssued = false
    this.WasMisconduct = false
    this.Violations = new ViolationsModel()
    this.SafetyRulesFollowed = false
    this.BondExclusionRecommended = false
    this.SafeDriverBonusExclusionRecommended = false
    this.DotRecordable = false
  }
}
