import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import DatePicker from "../Components/DatePicker"
import Spinner from "../Components/Spinner"
import { jobify } from "../Utils/HelperFunctions"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function UtilityInfo({ report, update, safetyTeam }) {
  //--- State Variables ---//
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())
  const [saving, setSaving] = useState(false)

  //--- Handlers and helper functions ---//
  const navigate = useNavigate()
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report) navigate("/Error")
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  })

  //--- Save and movev on to details ---//
  const handleGeneralInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(generalInfo)
    setSaving(false)
    navigate("/UtilityStrikeDetails")
  }

  //--- JSX rendered ---//
  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      {(report.ReportType !== "None" && (
        <>
          <h2>Utility Strike General Information</h2>
          <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
            <DatePicker info={generalInfo} set={setGeneralInfo} />
            <label htmlFor="name">Name of the person completing this report:</label>
            <input
              type="text"
              id="name"
              required
              maxLength="35"
              placeholder="Your name"
              value={generalInfo.Reporter?.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee # of the person completing this report:</label>
            <input
              type="text"
              id="employeeNumber"
              maxLength="4"
              placeholder="Your employee #"
              value={generalInfo.Reporter.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, EmployeeNumber: e.target.value }
                }))
              }
            />
            <label htmlFor="jobNumber">Job number:</label>
            <input
              type="text"
              id="jobNumber"
              required
              maxLength="7"
              placeholder="Job where the strike occurred"
              value={generalInfo.JobNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  JobNumber: jobify(e.target.value)
                }))
              }
            />
            <label htmlFor="supervisor">Supervisor at jobsite:</label>
            <input
              type="text"
              id="supervisor"
              required
              maxLength="35"
              placeholder="Supervisor's name"
              value={generalInfo.Supervisor.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Supervisor: { ...curr.Supervisor, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeAffected">Employee involved in the utility strike:</label>
            <input
              type="text"
              id="employeeAffected"
              required
              maxLength="35"
              placeholder="Employee's name"
              value={generalInfo.AffectedEmployee.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Name: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedNumber">Employee # of the person involved in the strike:</label>
            <input
              type="text"
              id="employeeAffectedNumber"
              maxLength="4"
              placeholder="Employee's #"
              value={generalInfo.AffectedEmployee.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    EmployeeNumber: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="vehicleNumber">PCI vehicle involved in the strike:</label>
            <input
              type="text"
              id="vehicleNumber"
              maxLength="16"
              placeholder="Vehicle #"
              value={generalInfo.VehicleNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  VehicleNumber: e.target.value
                }))
              }
            />
            <label htmlFor="equipmentNumber">PCI equipment involved in the strike:</label>
            <input
              type="text"
              id="equipmentNumber"
              maxLength="16"
              placeholder="Equipment #"
              value={generalInfo.EquipmentNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  EquipmentNumber: e.target.value
                }))
              }
            />
            <label htmlFor="safetyTeamInput">Person the strike was reported to:</label>
            <input
              id="safetyTeamInput"
              list="safetyTeamContacted"
              placeholder="Select or enter custom"
              value={generalInfo.SupervisorReportedTo?.Name}
              maxLength="35"
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  SupervisorReportedTo: {
                    ...curr.SupervisorReportedTo,
                    Name: e.target.value
                  }
                }))
              }
            />
            <datalist id="safetyTeamContacted" role="list">
              {safetyTeam.map((member, i) => {
                return (
                  <option value={member} key={i}>
                    {member}
                  </option>
                )
              })}
            </datalist>
            <div className="save-back-button-container">
              <input className="save-back-button" type="submit" value={saving ? "Saving..." : "Save and Continue"} />
            </div>
          </form>
        </>
      )) || <Spinner />}
    </div>
  )
}
