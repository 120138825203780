export default function DetailedInfoSummary({ info }) {
  return (
    <div className="summary-container">
      <table>
        <tbody>
          <tr>
            <td>Incident location</td>
            <td>{info.IncidentLocationDescription}</td>
          </tr>
          <tr>
            <td>Incident description</td>
            <td>{info.IncidentDescription}</td>
          </tr>
          <tr>
            <td>Incident cause</td>
            <td>{info.IncidentCause}</td>
          </tr>
          <tr>
            <td>Prevention actions</td>
            <td>{info.PossiblePreventionActions}</td>
          </tr>
          <tr>
            <td>PCI at fault?</td>
            <td>{info.PciAtFault ? "Yes" : "No"}</td>
          </tr>
          {!info.PciAtFault && (
            <tr>
              <td>Party at fault</td>
              <td>{info.PartyAtFault}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
