import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../Styles/GeneralInfo.css"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import DatePicker from "../Components/DatePicker"
import NonEmployeeInfo from "../Components/NonEmployeeInfo"
import Spinner from "../Components/Spinner"

//--- This a dead feature. Leaving it in in the event we decide to implement it later on. ---//

export default function AccidentInfo({ report, update, safetyTeam, spanish, user }) {
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())

  const navigate = useNavigate()

  useEffect(() => {
    if (!report) navigate("/Error")
    if (!user) navigate("/Login")
  })

  const addNonEmployeePhone = (phone) => {
    setGeneralInfo((curr) => ({
      ...curr,
      Reporter: {
        ...curr.Reporter,
        PhoneNumber: phone
      }
    }))
  }

  const addNonEmployeeEmail = (email) => {
    setGeneralInfo((curr) => ({
      ...curr,
      Reporter: {
        ...curr.Reporter,
        EmailAddress: email
      }
    }))
  }

  const handleGeneralInfoSubmit = (e) => {
    e.preventDefault()
    update(generalInfo)
    navigate("/AccidentDetails")
  }

  return (
    <div className="general-info-container">
      {(report.ReportType !== "None" && (
        <>
          <h2>{spanish ? "Información de Accidente General" : "Accident General Information"}</h2>
          <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
            <DatePicker info={generalInfo} set={setGeneralInfo} spanish={spanish} />
            <label htmlFor="name">{spanish ? "Su nombre" : "Your name"}</label>
            <input
              type="text"
              id="name"
              required={true}
              value={generalInfo.Reporter.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, Name: e.target.value }
                }))
              }
            />
            <NonEmployeeInfo
              setPhone={addNonEmployeePhone}
              setEmail={addNonEmployeeEmail}
              info={generalInfo.Reporter}
              spanish={spanish}
            />
            <label htmlFor="employeeNumber">Employee Number: </label>
            <input
              type="text"
              id="employeeNumber"
              value={generalInfo.Reporter.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((currentInfo) => ({
                  ...currentInfo,
                  Reporter: {
                    ...currentInfo.Reporter,
                    EmployeeNumber: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="jobNumber">Job Number: </label>
            <input
              type="text"
              id="jobNumber"
              value={generalInfo.JobNumber}
              onChange={(e) =>
                setGeneralInfo((currentInfo) => ({
                  ...currentInfo,
                  JobNumber: e.target.value
                }))
              }
            />
            <label htmlFor="supervisor">Supervisor at jobsite</label>
            <input
              type="text"
              id="supervisor"
              value={generalInfo.Supervisor.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Supervisor: { ...curr.Supervisor, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee Affected: </label>
            <input
              type="text"
              id="employeeAffected"
              value={generalInfo.AffectedEmployee.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Name: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedNumber">Affected Employee Number:</label>
            <input
              type="text"
              id="employeeAffectedNumber"
              value={generalInfo.AffectedEmployee.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    EmployeeNumber: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedDuties">Affected Employee duties:</label>
            <input
              type="text"
              id="employeeAffectedDuties"
              value={generalInfo.EmployeeDuties}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  EmployeeDuties: e.target.value
                }))
              }
            />
            <label htmlFor="vehicleNumber">Vehicle Number:</label>
            <input
              type="text"
              id="vehicleNumber"
              value={generalInfo.VehicleNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  VehicleNumber: e.target.value
                }))
              }
            />
            <label htmlFor="equipmentNumber">Equipment Number:</label>
            <input
              type="text"
              id="equipmentNumber"
              value={generalInfo.EquipmentNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  EquipmentNumber: e.target.value
                }))
              }
            />
            <label htmlFor="safetyTeamInput">Person the injury was reported to</label>
            <input
              id="safetyTeamInput"
              type="text"
              value={generalInfo.SupervisorReportedTo.Name}
              placeholder="name"
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  SupervisorReportedTo: {
                    ...curr.SupervisorReportedTo,
                    Name: e.target.value
                  }
                }))
              }
            />
            <div className="save-back-button-container">
              <input type="submit" className="save-back-button" value="Save and Continue" />
            </div>
          </form>
        </>
      )) || <Spinner />}
    </div>
  )
}
