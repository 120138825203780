import { useEffect, useState } from "react"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import { useNavigate } from "react-router-dom"

export default function WitnessDetails({ report, update, user }) {
  //--- State variables ---//
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  //--- Error handler and guard for user being set to guest ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
    if (user && user.name !== "guest") navigate("/Login")
  })

  //--- Saves progress and navigates to additional information page ---//
  const handleDetailsInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(detailsInfo, user.accessToken)
    setSaving(false)
    navigate("/AdditionalInfo")
  }

  return (
    <div className="general-info-container">
      <h2>Detailed information</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="incidentLocation">Where did the incident happen?</label>
        <textarea
          id="incidentLocation"
          className="description-textarea"
          rows="6"
          placeholder="Be specific. i.e. road intersections, nearby landmarks or geographical features, etc"
          maxLength="1024"
          value={detailsInfo.IncidentLocationDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentLocationDescription: e.target.value
            }))
          }
        />
        <label htmlFor="reportDeatiledIinfo">Describe the incident in detail</label>
        <textarea
          id="reportDeatiledIinfo"
          className="description-textarea"
          rows="16"
          maxLength="2048"
          placeholder="Include all details you think are important"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="reportCauseInfo">What was the cause of the incident?</label>
        <textarea
          type="text"
          id="reportCauseInfo"
          className="description-textarea"
          rows="10"
          maxLength="1024"
          placeholder="Who or what do you believe was the cause of the incident. Include any that apply."
          value={detailsInfo.IncidentCause}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentCause: e.target.value
            }))
          }
        />
        <label htmlFor="reportPreventionInfo">What could you have done to prevent the incident?</label>
        <textarea
          type="text"
          id="reportPreventionInfo"
          className="description-textarea"
          rows="12"
          maxLength="1024"
          placeholder="Be as detailed as possible"
          value={detailsInfo.PossiblePreventionActions}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              PossiblePreventionActions: e.target.value
            }))
          }
        />
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/WitnessInfo")}>
            Go back
          </button>
          <input type="submit" className="save-back-button" value={saving ? "Saving..." : "Save and Continue"} />
        </div>
      </form>
    </div>
  )
}
