export default function HospitalSummary({ info }) {
  return (
    <div className="summary-container">
      <table>
        <tbody>
          <tr>
            <td>Hospital / clinic name</td>
            <td>{info.HospitalName}</td>
          </tr>
          <tr>
            <td>Hospital city</td>
            <td>{info.City}</td>
          </tr>
          <tr>
            <td>Hospital state</td>
            <td>{info.State}</td>
          </tr>
          <tr>
            <td>Reported to</td>
            <td>{info.SafetyTeamMemberContacted.Name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
