import { useEffect, useState } from "react"

export default function GPSHelper({ active, addLocation }) {
  const [gpsData, setGpsData] = useState({})

  useEffect(() => {
    if (!active) {
      setGpsData({ latitude: "", longitude: "" })
      addLocation({ latitude: "", longitude: "" })
    } else
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          addLocation(pos.coords)
          setGpsData(pos.coords)
        },
        (err) => console.error(err)
      )
  }, [active])

  return (
    <div className="radio-container">
      <label htmlFor="lat">
        latitude
        <h5>{gpsData.latitude}</h5>
      </label>
      <label htmlFor="long">
        longitude
        <h5>{gpsData.longitude}</h5>
      </label>
    </div>
  )
}
