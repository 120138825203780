import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import GPSHelper from "../Components/GPSHelper"

//--- This a dead feature. Leaving it in in the event we decide to implement it later on. ---//

function DamageTheftDetails({ report, update, user }) {
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [GPS, setGPS] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
    if (!user) navigate("/Login")
  })

  const handleAddGps = ({ latitude, longitude }) => {
    setDetailsInfo((curr) => ({
      ...curr,
      IncidentReportLatitude: latitude,
      IncidentReportLongitude: longitude
    }))
  }

  const handleDetailInfoSubmit = (e) => {
    e.preventDefault()
    update(detailsInfo)
    navigate("/Witnesses")
  }
  return (
    <div className="general-info-container">
      <h2>DamageTheftDetails</h2>
      <label htmlFor="reportDeatiledIinfo">Describe the damage or the theft in detail</label>
      <form onSubmit={(e) => handleDetailInfoSubmit(e)}>
        <textarea
          id="reportDeatiledIinfo"
          className="description-textarea"
          rows="12"
          maxLength="1024"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="gpsLocation">Drop a pin on your current location?</label>
        <div id="gpsLocation" className="radio-container">
          <label htmlFor="gpsYes">
            YES
            <input type="radio" id="gpsYes" checked={GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
          <label htmlFor="gpsNo">
            NO
            <input type="radio" id="gpsNo" checked={!GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
        </div>
        <div className={GPS ? "optional-field-visible" : "optional-field-hidden"}>
          {GPS && <GPSHelper active={GPS} addLocation={handleAddGps} />}
        </div>
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/DamageTheftInfo")}>
            Go Back
          </button>
          <input type="submit" className="save-back-button" value="Save and Continue" />
        </div>
      </form>
    </div>
  )
}
export default DamageTheftDetails
