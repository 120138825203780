import { useState } from "react"
import { PersonModel } from "../Classes/PersonModel"
import "../Styles/People.css"

export default function EmployeeForm({ addEmployee }) {
  const [newEmployee, setNewEmployee] = useState(new PersonModel())

  const handleAddNewPersonSubmit = (e) => {
    e.preventDefault()
    addEmployee(newEmployee)
    setNewEmployee(new PersonModel())
  }
  return (
    <div id="personForm" className="add-employee-form">
      <label htmlFor="empName">Employee name:</label>
      <input
        id="empName"
        type="text"
        required={newEmployee.EmployeeNumber !== "" || newEmployee.PhoneNumber !== ""}
        maxLength="35"
        placeholder="Name"
        value={newEmployee.Name}
        onChange={(e) => setNewEmployee((curr) => ({ ...curr, Name: e.target.value }))}
      />
      <label htmlFor="empNumber">Employee number:</label>
      <input
        id="empNumber"
        type="text"
        maxLength="4"
        placeholder="Employee #"
        value={newEmployee.EmployeeNumber}
        onChange={(e) =>
          setNewEmployee((curr) => ({
            ...curr,
            EmployeeNumber: e.target.value
          }))
        }
      />
      <label htmlFor="empPhone">Employee phone:</label>
      <input
        id="empPhone"
        type="text"
        maxLength="16"
        placeholder="Phone #"
        value={newEmployee.PhoneNumber}
        onChange={(e) =>
          setNewEmployee((curr) => ({
            ...curr,
            PhoneNumber: e.target.value
          }))
        }
      />
      <button className="save-back-button" style={{ marginTop: "1em" }} onClick={(e) => handleAddNewPersonSubmit(e)}>
        Add Employee
      </button>
    </div>
  )
}
