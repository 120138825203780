import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GPSHelper from "../Components/GPSHelper"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"

//--- This is a dead feature. Leaving it in in the event we decide to implement it later on. ---//

export default function AccidentDetails({ update, report, user }) {
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [GPS, setGPS] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (report.ReportType === "None") navigate("/Error")
    if (!user) navigate("/Login")
  })

  const handleAddGps = ({ latitude, longitude }) => {
    setDetailsInfo((curr) => ({
      ...curr,
      IncidentReportLatitude: latitude,
      IncidentReportLongitude: longitude
    }))
  }

  const handleDetailsInfoSubmit = () => {
    update(detailsInfo)
    navigate("/Witnesses")
  }

  return (
    <div className="general-info-container">
      <h2>Accident Details</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="reportDeatiledIinfo">Describe the accident in detail</label>
        <textarea
          id="reportDeatiledIinfo"
          className="description-textarea"
          rows="12"
          maxLength="1024"
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="reportCauseInfo">What was the cause of the accident?</label>
        <textarea
          type="text"
          id="reportCauseInfo"
          className="description-textarea"
          rows="12"
          maxLength="1024"
          value={detailsInfo.IncidentCause}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentCause: e.target.value
            }))
          }
        />
        <label htmlFor="pciAtFault">Was PCI at fault for this accident?</label>
        <div id="pciAtFault" className="radio-container">
          <label htmlFor="atFaultYes">
            YES
            <input
              type="radio"
              id="atFaultYes"
              checked={detailsInfo.PciAtFault}
              onChange={() =>
                setDetailsInfo((curr) => ({
                  ...curr,
                  PciAtFault: !curr.PciAtFault
                }))
              }
            />
          </label>
          <label htmlFor="atFaultNo">
            NO
            <input
              type="radio"
              id="atFaultNo"
              checked={!detailsInfo.PciAtFault}
              onChange={() =>
                setDetailsInfo((curr) => ({
                  ...curr,
                  PciAtFault: !curr.PciAtFault
                }))
              }
            />
          </label>
        </div>
        <div className={detailsInfo.PciAtFault ? "optional-field-hidden" : "optional-field-visible"}>
          <label htmlFor="atFaultParty">Who is responsible for the accident and why?</label>
          <textarea
            id="atFaultParty"
            className="description-textarea"
            rows="6"
            maxLength="1024"
            placeholder="Explain who is at fault and why"
            value={detailsInfo.PartyAtFault}
            onChange={(e) =>
              setDetailsInfo((curr) => ({
                ...curr,
                PartyAtFault: e.target.value
              }))
            }
          />
        </div>
        <label htmlFor="preventionInfo">What could have been done to prevent this accident?</label>
        <textarea
          id="preventionInfo"
          className="description-textarea"
          rows="6"
          maxLength="1024"
          placeholder="Be detailed"
          value={detailsInfo.PossiblePreventionActions}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              PossiblePreventionActions: e.target.value
            }))
          }
        />
        <label htmlFor="gpsLocation">Drop a pin on your current location?</label>
        <div id="gpsLocation" className="radio-container">
          <label htmlFor="gpsYes">
            YES
            <input type="radio" id="gpsYes" checked={GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
          <label htmlFor="gpsNo">
            NO
            <input type="radio" id="gpsNo" checked={!GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
        </div>
        <div className={GPS ? "optional-field-visible" : "optional-field-hidden"}>
          {GPS && <GPSHelper active={GPS} addLocation={handleAddGps} />}
        </div>
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/AccidentInfo")}>
            Go Back
          </button>
          <input type="submit" className="save-back-button" value="Save and Continue" />
        </div>
      </form>
    </div>
  )
}
