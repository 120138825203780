import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { HospitalModel } from "../Classes/HospitalModel"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function Hospital({ report, update, NA, setNA, safetyTeam }) {
  //--- State variables ---//
  const [hospitalInfo, setHospitalInfo] = useState(report.HospitalInfo || new HospitalModel())
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Saves progress and navigates to appropriate page ---//
  const handleHospitalInfoSubmit = async (e) => {
    e.preventDefault()
    await update(hospitalInfo)
    navigate(report.ReportType === "Injury" || report.ReportType === "Other" ? "/PPE" : "/AdditionalInfo")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Hospital / Doctor Information</h2>
      <label htmlFor="hospitalNA" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Did the employee go to the hospital or see a doctor at the direction of the PCI safety team?
      </label>
      <div id="hospitalNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>
      <form onSubmit={handleHospitalInfoSubmit}>
        {!NA && (
          <>
            <label htmlFor="hospitalName">Hospital / Clinic name:</label>
            <input
              type="text"
              id="hospitalName"
              required
              maxLength="100"
              placeholder="Full name"
              value={hospitalInfo.HospitalName}
              onChange={(e) =>
                setHospitalInfo((curr) => ({
                  ...curr,
                  HospitalName: e.target.value
                }))
              }
            />
            <label htmlFor="hospitalCity">City:</label>
            <input
              type="text"
              id="hospitalCity"
              required={true}
              maxLength="30"
              placeholder="City"
              value={hospitalInfo.City}
              onChange={(e) =>
                setHospitalInfo((curr) => ({
                  ...curr,
                  City: e.target.value
                }))
              }
            />
            <label htmlFor="hospitalState">State:</label>
            <input
              type="text"
              id="hospitalState"
              required={true}
              maxLength="30"
              placeholder="State"
              value={hospitalInfo.State}
              onChange={(e) =>
                setHospitalInfo((curr) => ({
                  ...curr,
                  State: e.target.value
                }))
              }
            />
            <label htmlFor="doctorName">Attending doctor's name:</label>
            <input
              type="text"
              id="doctorName"
              required={true}
              maxLength="35"
              placeholder="Doctor's name"
              value={hospitalInfo.Doctor.Name}
              onChange={(e) =>
                setHospitalInfo((curr) => ({
                  ...curr,
                  Doctor: { ...curr.Doctor, Name: e.target.value }
                }))
              }
            />
            <>
              <label htmlFor="drugtest">Was the employeed drug tested?</label>
              <div id="drugtest" className="radio-container">
                <label htmlFor="testYes">
                  YES
                  <input
                    type="radio"
                    id="testYes"
                    name="test"
                    checked={hospitalInfo.EmployeeDrugTested}
                    onChange={() =>
                      setHospitalInfo((curr) => ({ ...curr, EmployeeDrugTested: !curr.EmployeeDrugTested }))
                    }
                  />
                </label>
                <label htmlFor="testNo">
                  NO
                  <input
                    type="radio"
                    id="testNo"
                    name="test"
                    checked={!hospitalInfo.EmployeeDrugTested}
                    onChange={() =>
                      setHospitalInfo((curr) => ({ ...curr, EmployeeDrugTested: !curr.EmployeeDrugTested }))
                    }
                  />
                </label>
              </div>
              {!hospitalInfo.EmployeeDrugTested && (
                <>
                  <label htmlFor="test-comments">Why wasn't the employee drug tested?</label>
                  <textarea
                    id="test-comments"
                    placeholder="Explain why"
                    required={!hospitalInfo.EmployeeDrugTested}
                    maxLength="1024"
                    value={hospitalInfo.NoDrugTestComments}
                    onChange={(e) => setHospitalInfo((curr) => ({ ...curr, NoDrugTestComments: e.target.value }))}
                  />
                </>
              )}
            </>
            <label htmlFor="safetyNotified">
              Was the PCI safety team notified of the hospitalization or doctor visit?
            </label>
            <div id="safetyNotified" className="radio-container">
              <label htmlFor="notifiedYes">
                YES
                <input
                  type="radio"
                  id="notifiedYes"
                  checked={hospitalInfo.SafetyNotifiedOfHospitalizationNeed}
                  onChange={() =>
                    setHospitalInfo((curr) => ({
                      ...curr,
                      SafetyNotifiedOfHospitalizationNeed: !curr.SafetyNotifiedOfHospitalizationNeed
                    }))
                  }
                />
              </label>
              <label htmlFor="notifiedNo">
                NO
                <input
                  type="radio"
                  id="notifiedNo"
                  checked={!hospitalInfo.SafetyNotifiedOfHospitalizationNeed}
                  onChange={() =>
                    setHospitalInfo((curr) => ({
                      ...curr,
                      SafetyNotifiedOfHospitalizationNeed: !curr.SafetyNotifiedOfHospitalizationNeed
                    }))
                  }
                />
              </label>
            </div>

            <label htmlFor="safetyTeamInput">Safety Team Member Contacted: </label>
            <input
              id="safetyTeamInput"
              list="safetyTeamContacted"
              placeholder="Select or enter custom"
              required={hospitalInfo.SafetyNotifiedOfHospitalizationNeed}
              maxLength="35"
              value={hospitalInfo.SafetyTeamMemberContacted?.Name}
              onChange={(e) =>
                setHospitalInfo((curr) => ({
                  ...curr,
                  SafetyTeamMemberContacted: {
                    ...curr.SafetyTeamMemberContacted,
                    Name: e.target.value
                  }
                }))
              }
            />
            <datalist id="safetyTeamContacted">
              {safetyTeam.map((member, i) => {
                return (
                  <option value={member} key={i}>
                    {member}
                  </option>
                )
              })}
            </datalist>
          </>
        )}
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/Camera")}>
            Go Back
          </button>
          <input className="save-back-button" type="submit" value="Save and continue" />
        </div>
      </form>
    </div>
  )
}
