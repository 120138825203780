import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import DatePicker from "../Components/DatePicker"

//--- This a dead feature. Leaving it in in the event we decide to implement it later on. ---//

function DamageTheftInfo({ report, update, safetyTeam, user }) {
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())

  const navigate = useNavigate()

  useEffect(() => {
    if (!report) navigate("/Error")
    if (!user) navigate("/Login")
  })

  const handleGeneralInfoSubmit = (e) => {
    e.preventDefault()
    update(generalInfo)
    navigate("/DamageTheftDetails")
  }

  return (
    <div className="general-info-container">
      {(report.ReportType !== "None" && (
        <>
          <h2>Damage / Theft Info</h2>
          <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
            <DatePicker info={generalInfo} set={setGeneralInfo} />
            <label htmlFor="name">Your name</label>
            <input
              type="text"
              id="name"
              required={true}
              value={generalInfo.Reporter.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee Number: </label>
            <input
              type="text"
              id="employeeNumber"
              value={generalInfo.Reporter.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  Reporter: { ...curr.Reporter, EmployeeNumber: e.target.value }
                }))
              }
            />
            <label htmlFor="employeeNumber">Job Number: </label>
            <input
              type="text"
              id="jobNumber"
              value={generalInfo.JobNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  JobNumber: e.target.value
                }))
              }
            />
            <label htmlFor="employeeNumber">Employee Affected: </label>
            <input
              type="text"
              id="employeeAffected"
              value={generalInfo.AffectedEmployee.Name}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Name: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="employeeAffectedNumber">Affected Employee Number:</label>
            <input
              type="text"
              id="employeeAffectedNumber"
              value={generalInfo.AffectedEmployee.EmployeeNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  AffectedEmployee: {
                    ...curr.AffectedEmployee,
                    Number: e.target.value
                  }
                }))
              }
            />
            <label htmlFor="vehicleNumber">Vehicle Number:</label>
            <input
              type="text"
              id="vehicleNumber"
              value={generalInfo.VehicleNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  VehicleNumber: e.target.value
                }))
              }
            />
            <label htmlFor="equipmentNumber">Equipment Number:</label>
            <input
              type="text"
              id="equipmentNumber"
              value={generalInfo.EquipmentNumber}
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  EquipmentNumber: e.target.value
                }))
              }
            />
            <label htmlFor="safetyTeamInput">Person the damage or theft was reported to</label>
            <input
              id="safetyTeamInput"
              type="text"
              value={generalInfo.SupervisorReportedTo.Name}
              placeholder="name"
              onChange={(e) =>
                setGeneralInfo((curr) => ({
                  ...curr,
                  SupervisorReportedTo: {
                    ...curr.SupervisorReportedTo,
                    Name: e.target.value
                  }
                }))
              }
            />
            <div className="save-back-button-container">
              <input className="save-back-button" type="submit" value="Save and continue" />
            </div>
          </form>
        </>
      )) || <h2>Loading...</h2>}
    </div>
  )
}
export default DamageTheftInfo
