import { useEffect, useState } from "react"
import { GeneralInfoModel } from "../Classes/GeneralInfoModel"
import { useNavigate } from "react-router-dom"
import DatePicker from "../Components/DatePicker"

export default function WitnessInfo({ report, update, user }) {
  //--- State variables ---//
  const [generalInfo, setGeneralInfo] = useState(report.GeneralInfo || new GeneralInfoModel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  //--- Error handler and guard for witness being set to guest ---//
  useEffect(() => {
    if (report.ReportType === "None") navigate("/Error")
    if (user && user.name !== "guest") navigate("/Login")
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  })

  //--- Saves progess and navigates to detailed information page ---//
  const handleGeneralInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(generalInfo, user.accessToken)
    setSaving(false)
    navigate("/WitnessDetails")
  }

  return (
    <div className="general-info-container">
      <form onSubmit={(e) => handleGeneralInfoSubmit(e)}>
        <DatePicker info={generalInfo} set={setGeneralInfo} />
        <label htmlFor="name">Your name</label>
        <input
          type="text"
          id="name"
          required={true}
          maxLength="35"
          placeholder="Enter your name"
          value={generalInfo.Reporter.Name}
          onChange={(e) =>
            setGeneralInfo((curr) => ({
              ...curr,
              Reporter: { ...curr.Reporter, Name: e.target.value }
            }))
          }
        />
        <label htmlFor="employeeNumber">Employee Number: </label>
        <input
          type="text"
          id="employeeNumber"
          maxLength="4"
          placeholder="If applicable"
          value={generalInfo.Reporter.EmployeeNumber}
          onChange={(e) =>
            setGeneralInfo((currentInfo) => ({
              ...currentInfo,
              Reporter: {
                ...currentInfo.Reporter,
                EmployeeNumber: e.target.value
              }
            }))
          }
        />
        <label htmlFor="jobNumber">Job Number: </label>
        <input
          type="text"
          id="jobNumber"
          maxLength="7"
          placeholder="If known"
          value={generalInfo.JobNumber}
          onChange={(e) =>
            setGeneralInfo((currentInfo) => ({
              ...currentInfo,
              JobNumber: e.target.value
            }))
          }
        />
        <label htmlFor="employeeAffected">Employee Affected: </label>
        <input
          type="text"
          id="employeeAffected"
          maxLength="35"
          placeholder="If known"
          value={generalInfo.AffectedEmployee.Name}
          onChange={(e) =>
            setGeneralInfo((curr) => ({
              ...curr,
              AffectedEmployee: {
                ...curr.AffectedEmployee,
                Name: e.target.value
              }
            }))
          }
        />
        <div className="save-back-button-container">
          <input type="submit" className="save-back-button" value={saving ? "Saving..." : "Save and Continue"} />
        </div>
      </form>
    </div>
  )
}
