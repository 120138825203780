import "../Styles/Witness.css"

export default function Witness({ witness, remove }) {
  return (
    <div className="witness-container">
      <div>
        <h4>{witness.Name}</h4>
        <h5>{witness.PhoneNumber}</h5>
        <h5>{witness.EmailAddress}</h5>
      </div>
      <button className="remove-witness-btn" onClick={() => remove(witness.UUID)}>
        Remove
      </button>
    </div>
  )
}
