import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { PoliceInfoModel } from "../Classes/PoliceInfoModel"
import { PersonModel } from "../Classes/PersonModel"
import { UnauthenticatedTemplate } from "@azure/msal-react"

export default function Police({ report, update, spanish, NA, setNA }) {
  //--- State variables ---//
  const [policeInfo, setPoliceInfo] = useState(report.PoliceInfo || new PoliceInfoModel())
  const [citationIssued, setCitationIssued] = useState(
    report.PoliceInfo !== null && (report.PoliceInfo.CitationNumber !== "" || report.PoliceInfo.CitationReason !== "")
  )
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Saves progress and navigates to appropriate page ---//
  const handlePoliceInfoSSubmit = (e) => {
    e.preventDefault()
    update(policeInfo)
    const type = report.ReportType
    navigate(type === "Accident" || type === "Injury" || type === "Other" ? "/HospitalInfo" : "/AdditionalInfo")
  }

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="Login" />
      </UnauthenticatedTemplate>
      <h2>{spanish ? "Información de Policía" : "Police Information"}</h2>
      <label htmlFor="policeNA" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Were the police involved?
      </label>
      <div id="policeNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>

      <form className="police-info-form" onSubmit={(e) => handlePoliceInfoSSubmit(e)}>
        {!NA && (
          <>
            <label htmlFor="department-name">Name of the responding police department:</label>
            <input
              type="text"
              id="department-name"
              maxLength="64"
              placeholder="Enter full dept. name"
              value={policeInfo.RespondingDepartment}
              onChange={(e) =>
                setPoliceInfo((curr) => ({
                  ...curr,
                  RespondingDepartment: e.target.value
                }))
              }
            />
            <label htmlFor="police-name">Name of the responding police officer:</label>
            <input
              type="text"
              id="police-name"
              maxLength="35"
              placeholder="Officer's name"
              value={policeInfo.PrimaryOfficer.Name}
              onChange={(e) =>
                setPoliceInfo((curr) => ({
                  ...curr,
                  PrimaryOfficer: { ...curr.PrimaryOfficer, Name: e.target.value }
                }))
              }
            />
            <label htmlFor="case-number">Police report #</label>
            <input
              type="text"
              id="case-number"
              maxLength="16"
              placeholder="If known"
              value={policeInfo.ReportNumber}
              onChange={(e) =>
                setPoliceInfo((curr) => ({
                  ...curr,
                  ReportNumber: e.target.value
                }))
              }
            />
            <label htmlFor="citationIssued">Was a citation issued?</label>
            <div id="citationIssued" className="radio-container">
              <label htmlFor="atFaultYes">
                YES
                <input
                  type="radio"
                  id="atFaultYes"
                  checked={citationIssued}
                  onChange={() => {
                    setPoliceInfo((curr) => ({ ...curr, CitationRecipient: new PersonModel() }))
                    setCitationIssued((curr) => !curr)
                  }}
                />
              </label>
              <label htmlFor="atFaultNo">
                NO
                <input
                  type="radio"
                  id="atFaultNo"
                  checked={!citationIssued}
                  onChange={() => {
                    setPoliceInfo((curr) => ({
                      ...curr,
                      CitationNumber: "",
                      CitationReason: ""
                    }))
                    setCitationIssued((curr) => !curr)
                  }}
                />
              </label>
            </div>
            <div className={citationIssued ? "optional-field-visible" : "optional-field-hidden"}>
              <label htmlFor="citation-number">Citation Number:</label>
              <input
                type="text"
                id="citation-number"
                required={citationIssued}
                maxLength="16"
                placeholder="If known"
                value={policeInfo.CitationNumber}
                onChange={(e) =>
                  setPoliceInfo((curr) => ({
                    ...curr,
                    CitationNumber: e.target.value
                  }))
                }
              />
              <label htmlFor="citation-recipient">Who recieved the citation?</label>
              <input
                type="text"
                id="citation-recipient"
                required={citationIssued}
                maxLength="35"
                placeholder="Recipient's name"
                value={policeInfo.CitationRecipient?.Name}
                onChange={(e) =>
                  setPoliceInfo((curr) => ({
                    ...curr,
                    CitationRecipient: { ...curr.CitationRecipient, Name: e.target.value }
                  }))
                }
              />
              <label htmlFor="citation-reason">What was the citation for?</label>
              <input
                type="text"
                id="citation-reason"
                required={citationIssued}
                maxLength="1024"
                placeholder="Enter a reason"
                value={policeInfo.CitationReason}
                onChange={(e) =>
                  setPoliceInfo((curr) => ({
                    ...curr,
                    CitationReason: e.target.value
                  }))
                }
              />
            </div>
          </>
        )}
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/Camera")}>
            Go Back
          </button>
          <input className="save-back-button" type="submit" value="Save and Continue" />
        </div>
      </form>
    </div>
  )
}
