import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import WitnessForm from "../Components/WitnessForm"
import Witness from "../Components/Witness"
import "../Styles/People.css"
import { PersonModel } from "../Classes/PersonModel"

export default function Witnesses({ update, report, NA, setNA }) {
  //--- State variables ---//
  const [witnesses, setWitnesses] = useState(report.WitnessInfos)
  const [newWitness, setNewWitness] = useState(new PersonModel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Adds witness to array ---//
  const addWitness = () => {
    setWitnesses((curr) => [...curr, newWitness])
  }

  //--- Remove witness from array ---//
  const deleteWitness = (id) => {
    setWitnesses((curr) => curr.filter(({ UUID }) => UUID !== id))
  }

  const isCompleteNewWitness = () => newWitness.Name && newWitness.PhoneNumber && newWitness.EmailAddress

  return (
    <div className="people-form">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Witnesses</h2>
      <label htmlFor="pciAtFault" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Were there any witnesses to the{" "}
        {report.ReportType === "UtilityStrike"
          ? "utility strike"
          : report.ReportType === "Other" || report.ReportType === "Employee"
          ? "incident"
          : "injury"}
        ?
      </label>
      <div id="pciAtFault" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>
      {!NA && (
        <>
          <label htmlFor="peopleForm">
            Enter each witness' info and click "Add Witness" to add that witness to the list.
            <br /> (Each witness will be sent an email with instructions on how to fill-out a separate witness report.)
            <br /> Click "Save and Continue" when you're done.
          </label>
          <WitnessForm update={update} addWitness={addWitness} newWitness={newWitness} setNewWitness={setNewWitness} />
          <div className="witnesses-container">
            {/* Renders the existing witnesses */}
            {witnesses &&
              witnesses.map((witness) => (
                <Witness
                  witness={witness}
                  remove={deleteWitness}
                  key={crypto.randomUUID()}
                  name={crypto.randomUUID()}
                />
              ))}
          </div>
        </>
      )}
      <div className="save-back-button-container">
        <button
          className="save-back-button"
          onClick={() => {
            navigate(`/${report.ReportType.replace(/Or/gi, "").replace(/Other/gi, "Incident")}Details`)
          }}
        >
          Go Back
        </button>
        <button
          type="submit"
          className="save-back-button"
          onClick={async () => {
            //--- Saves progress and navigates to camera page        ---//
            //--- Grab the currently entered witness and             ---//
            //--- add it to the array (if completed) before updating ---//
            setSaving(true)
            await update(isCompleteNewWitness() ? [...witnesses, newWitness] : witnesses)
            setSaving(false)
            navigate("/Camera")
          }}
        >
          {saving ? "Saving..." : "Save and Continue"}
        </button>
      </div>
    </div>
  )
}
