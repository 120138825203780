import { useState } from "react"

export default function ViolationType({ violationType, set, violations }) {
  const [otherType, setOtherType] = useState(violations.Comments || "")
  const property = violationType.replace(/\s/g, "")
  return (
    <div className="check-container">
      <label htmlFor={violationType}>{violationType}</label>
      <input
        id={violationType}
        type="checkbox"
        value={violationType}
        checked={violations[property]}
        onChange={(e) => {
          set((curr) => ({ ...curr, Violations: { ...curr.Violations, [property]: !curr.Violations[property] } }))
        }}
      />
      {violationType === "Other" && violations[property] && (
        <input
          type="text"
          value={otherType}
          placeholder="other type"
          required={violationType === "other"}
          onChange={(e) => setOtherType(e.target.value)}
          onBlur={() => set((curr) => ({ ...curr, Violations: { ...curr.Violations, Comments: otherType || null } }))}
        />
      )}
    </div>
  )
}
