import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { cameraMessages } from "../Utils/Constants"
import { sanitize } from "../Utils/HelperFunctions"
import Imagemodel from "../Classes/ImageModel"
import Image from "../Components/Image"

export default function Photos({ update, report, NA, setNA, user }) {
  //--- State variables ---//
  const [images, setImages] = useState(report.Photos || [])
  const [image, setImage] = useState(new Imagemodel())
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  //--- Error handler for missing report. Renders camera invisible if username is guest (this can be cleaned up now that authentication wraps this page) ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
    if (user && user.name === "guest") setNA(true)
  })

  //--- Add the selected image to the Photos array. ---//
  const addImage = (image) => setImages((curr) => [...curr, image])

  //--- Remove the selected image from the Photos array. ---//
  const deleteImage = () => {
    setImages((curr) => curr.filter((pic) => pic.UUID !== image.UUID))
    setImage(new Imagemodel())
  }

  //--- Image compression algorithm ---//
  const compressImage = async (file, { quality = 1, type = file.type }) => {
    // Get as image data
    const imageBitmap = await createImageBitmap(file)
    // Draw to canvas
    const canvas = document.createElement("canvas")
    canvas.width = imageBitmap.width
    canvas.height = imageBitmap.height
    const ctx = canvas.getContext("2d")
    ctx.drawImage(imageBitmap, 0, 0)
    // Turn into Blob
    return await new Promise((resolve) => canvas.toBlob(resolve, type, quality))
  }

  //--- Create a FileReader object and convert image blob to base64 string. Returns the base64 string as a Promise. ---//
  const blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }

  //--- Retrieve the data from the url and convert to base64. Sets image source. ---//
  const generateBase64 = (blob) => {
    blobToBase64(blob).then((fin) => setImage((curr) => ({ ...curr, Base64: sanitize(fin) })))
  }

  //--- Event handler for file upload / camera input ---//
  const handleSnap = async (e) => {
    const target = e.target
    if (target.files) {
      if (target.files.length) {
        const file = target.files[0]
        const compressedBlob = await compressImage(file, { quality: 0.5 })
        generateBase64(compressedBlob)
      }
    }
  }

  //--- Add or edit current image ---//
  const handleUpload = () => {
    setImages((curr) => curr.filter((img) => img.UUID !== image.UUID))
    addImage(image)
    setImage(new Imagemodel())
  }

  return (
    <div className="general-info-container">
      <h2>Photos</h2>
      <label htmlFor="photosNA" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Do you need to add any photos?
      </label>
      <div id="photosNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input
            type="radio"
            id="NAYes"
            checked={!NA}
            onChange={() => {
              if (!user || user.name !== "guest") setNA((curr) => !curr)
            }}
          />
        </label>
        <label htmlFor="NANo">
          NO
          <input
            type="radio"
            id="NANo"
            checked={NA}
            onChange={() => {
              if (!user || user.name !== "guest") setNA((curr) => !curr)
            }}
          />
        </label>
      </div>
      {!NA && (
        <>
          {/*--- Conditional message / instructions based on report type ---*/}
          {cameraMessages[report.ReportType]?.map((m) => (
            <h5 key={crypto.randomUUID()}>{m}</h5>
          ))}
          <div className="photo-upload-container">
            {!image.Base64 && (
              <>
                <label htmlFor="shutter">
                  <div className="shutter-button">
                    <h3>Take Photo</h3>
                  </div>
                </label>
                <input
                  type="file"
                  id="shutter"
                  accept="image/*"
                  capture="environment"
                  style={{ display: "none" }}
                  onChange={(e) => handleSnap(e)}
                />
                <label htmlFor="shutter2">
                  <div className="shutter-button">
                    <h3>Upload Photo</h3>
                  </div>
                </label>
                <input
                  type="file"
                  id="shutter2"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleSnap(e)}
                />
              </>
            )}
            {image.Base64 && (
              <div className="preview-container">
                <img src={image.Base64} alt="" />
                <textarea
                  maxLength="1024"
                  value={image.Description}
                  onChange={(e) =>
                    setImage((curr) => ({
                      ...curr,
                      Description: e.target.value
                    }))
                  }
                  placeholder="comments"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "1%"
                  }}
                >
                  <button className="save-back-button" onClick={deleteImage}>
                    Delete
                  </button>

                  <button className="save-back-button" onClick={handleUpload}>
                    {images.some((img) => img.UUID === image.UUID) ? "Save Changes" : "Upload"}
                  </button>
                </div>
              </div>
            )}
          </div>
          {/*--- Display existing images in report Photo list ---*/}
          <div className="images-container">
            {images &&
              images.map((image) => {
                return <Image key={image.UUID} imageData={image.Base64} view={() => setImage(image)} />
              })}
          </div>
        </>
      )}
      {user?.name === "guest" && (
        <>
          <h6>
            It looks like you're logged in as a guest. If you have any photos you need to add to this report, you can
            text them to 319.123.4567 or email them to photos@pcisafety.com. Please include the date of the incident and
            the job number(if known).
          </h6>
          {"ontouchstart" in window && (
            <a href="">
              <img src="Share.png" alt="share" style={{ height: "8vh", borderRadius: "8px" }} />
            </a>
          )}
        </>
      )}
      <div className="save-back-button-container">
        <button className="save-back-button" onClick={() => navigate("/Witnesses")}>
          Go Back
        </button>
        <button
          className="save-back-button"
          onClick={async () => {
            //--- Saves progress and navigates to police information page ---//
            setSaving(true)
            await update(images)
            setSaving(false)
            navigate("/PoliceInfo")
          }}
        >
          {saving ? "Saving... please wait" : "Save and Continue"}
        </button>
      </div>
    </div>
  )
}
