import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { summaryTypes } from "../Utils/Constants"
import { prettyDate } from "../Utils/HelperFunctions"
import GeneralInfoSummary from "../Components/SummarySections/GeneralInfoSummary"
import DetailedInfoSummary from "../Components/SummarySections/DetailedInfoSummary"
import Image from "../Components/Image"
import PoliceSummary from "../Components/SummarySections/PoliceSummary"
import HospitalSummary from "../Components/SummarySections/HospitalSummary"
import Comments from "../Components/SummarySections/Comments"
import MissingSectionWarning from "../Components/MissingSectionWarning"
import Spinner from "../Components/Spinner"

export default function ReportSummary({
  report,
  complete,
  completeWitness,
  reset,
  NASetters,
  user,
  setUser,
  retrieving
}) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if ("reportUUID" in localStorage || retrieving) {
      setLoading(report.ReportType === "None")
      return
    }
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  //--- Renders all completed sections for review by the reporter. Hides complete button if general or detailed info section missing ---//
  return (
    <div className="general-info-container">
      {(loading && <Spinner message="Retrieving your report..." />) || (
        <>
          <h2>{summaryTypes[report.ReportType]} Report Summary</h2>
          <h5 style={{ borderBottom: "1px solid black" }}>General Information</h5>
          {(report.GeneralInfo && <GeneralInfoSummary info={report.GeneralInfo} />) || (
            <MissingSectionWarning section="General information" type={report.ReportType} />
          )}
          <h5 style={{ borderBottom: "1px solid black" }}>Detailed information</h5>
          {(report.DetailedInfo && <DetailedInfoSummary info={report.DetailedInfo} />) || (
            <MissingSectionWarning section="Detailed information" type={report.ReportType} />
          )}
          {report.InjuryAcknowledgementInfo?.Signature && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>
                Employee injury information form signed by {report.GeneralInfo.AffectedEmployee?.Name} on{" "}
                {prettyDate(report.GeneralInfo.IncidentDatetime)}
              </h5>
              <div className="images-container"></div>
              <Image
                key={crypto.randomUUID()}
                imageData={report.InjuryAcknowledgementInfo.Signature.Base64}
                view={() => null}
              />
            </>
          )}
          {report.WitnessInfos?.length > 0 && (
            <div className="witness-conatiner">
              <h5 style={{ borderBottom: "1px solid black" }}>Witnesses:</h5>
              {report.WitnessInfos.map((witness) => {
                return (
                  <div style={{ marginBottom: "0.5em", backgroundColor: "white" }} key={crypto.randomUUID()}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{witness.Name}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{witness.EmailAddress}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          )}
          {report.Photos?.length > 0 && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>Photos:</h5>
              <div className="images-container">
                {report.Photos.map((photo) => (
                  <Image key={photo.UUID} imageData={photo.Base64} view={() => null} />
                ))}
              </div>
            </>
          )}
          {report.HospitalInfo && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>Hospital Information</h5>
              <HospitalSummary info={report.HospitalInfo} />
            </>
          )}
          {report.PoliceInfo && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>Police Information</h5>
              <PoliceSummary info={report.PoliceInfo} />
            </>
          )}
          {report.PPEInfos?.length > 0 && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>PPE</h5>
              {report.PPEInfos.map((ppe) => (
                <div style={{ marginBottom: "0.5em", backgroundColor: "white" }} key={crypto.randomUUID()}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Type</td>
                        <td>{ppe.Type}</td>
                      </tr>
                      <tr>
                        <td>Issue</td>
                        <td>{ppe.MissingFromPerson ? "Missing" : "Used Improperly"}</td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td>{ppe.Comments}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          )}
          {report.AdditionalInformation && (
            <>
              <h5 style={{ borderBottom: "1px solid black" }}>Comments</h5>
              <Comments info={report.AdditionalInformation} />
            </>
          )}
          {report.GeneralInfo && report.DetailedInfo && (
            <button
              className="confirm-button"
              onClick={async () => {
                const success = await (report.ReportType === "WitnessReport"
                  ? completeWitness(user.accessToken)
                  : complete())
                reset({ ReportType: "None" })
                setUser(null)
                NASetters.forEach((setter) => setter(false))
                navigate(success ? "/Confirmation" : "/Error")
              }}
            >
              <h3>Confirm and post report?</h3>
            </button>
          )}
        </>
      )}
    </div>
  )
}
