import { prettyDate, prettyTime } from "../../Utils/HelperFunctions"

export default function GeneralInfoSummary({ info }) {
  return (
    <div className="summary-container">
      <table>
        <tbody>
          <tr>
            <td>Incident date</td>
            <td>{prettyDate(info.IncidentDatetime)}</td>
          </tr>
          <tr>
            <td>Incident time</td>
            <td>{prettyTime(info.IncidentDatetime)}</td>
          </tr>
          <tr>
            <td>Reporter name</td>
            <td>{info.Reporter.Name}</td>
          </tr>
          <tr>
            <td>Reporter employee #</td>
            <td>{info.Reporter.EmployeeNumber}</td>
          </tr>
          <tr>
            <td>Job number</td>
            <td>{info.JobNumber}</td>
          </tr>
          <tr>
            <td>Affected employee name</td>
            <td>{info.AffectedEmployee?.Name}</td>
          </tr>
          <tr>
            <td>Affected employee #</td>
            <td>{info.AffectedEmployee?.EmployeeNumber}</td>
          </tr>
          {info.EmployeeDuties && (
            <tr>
              <td>Affected employee duties</td>
              <td>{info.EmployeeDuties}</td>
            </tr>
          )}
          <tr>
            <td>Supervisor name</td>
            <td>{info.Supervisor?.Name}</td>
          </tr>
          {info.VehicleNumber && (
            <tr>
              <td>Vehicle #</td>
              <td>{info.VehicleNumber}</td>
            </tr>
          )}
          {info.EquipmentNumber && (
            <tr>
              <td>Equipment #</td>
              <td>{info.EquipmentNumber}</td>
            </tr>
          )}
          <tr>
            <td>Person reported to</td>
            <td>{info.SupervisorReportedTo?.Name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
