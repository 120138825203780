import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { DetailedInfoModel } from "../Classes/DetailedInfoModel"
import GPSHelper from "../Components/GPSHelper"

export default function UtilityDetails({ report, update }) {
  //--- State variables ---//
  const [detailsInfo, setDetailsInfo] = useState(report.DetailedInfo || new DetailedInfoModel())
  const [GPS, setGPS] = useState(!!detailsInfo.IncidentReportLatitude || !!detailsInfo.IncidentReportLongitude)
  const [unknown, setUnknown] = useState(detailsInfo.PartyAtFault === "UNKNOWN")
  const [saving, setSaving] = useState(false)

  const navigate = useNavigate()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (report.ReportType === "None") navigate("/Error")
  })

  //--- set Gps info ---//
  const handleAddGps = ({ latitude, longitude }) => {
    setDetailsInfo((curr) => ({
      ...curr,
      IncidentReportLatitude: (latitude && latitude.toFixed(5)) || "",
      IncidentReportLongitude: (longitude && longitude.toFixed(5)) || ""
    }))
  }

  //--- Saves progress and navigates to witness information page ---//
  const handleDetailsInfoSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await update(detailsInfo)
    setSaving(false)
    navigate("/Witnesses")
  }

  return (
    <div className="general-info-container">
      <h2>Utility Strike Details</h2>
      <form onSubmit={(e) => handleDetailsInfoSubmit(e)}>
        <label htmlFor="reportDeatiledIinfo">Describe the utility strike in detail:</label>
        <textarea
          id="reportDeatiledIinfo"
          className="description-textarea"
          required
          maxLength="2048"
          rows="16"
          placeholder="Include the names of anyone who came to fix the utility, especially if they say PCI was not at fault."
          value={detailsInfo.IncidentDescription}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentDescription: e.target.value
            }))
          }
        />
        <label htmlFor="reportCauseInfo">What caused the utility strike?</label>
        <textarea
          type="text"
          id="reportCauseInfo"
          className="description-textarea"
          placeholder="Who or what was the cause of the strike? Include details of how far from the nearest locate the strike happened."
          rows="10"
          required
          maxLength="1024"
          value={detailsInfo.IncidentCause}
          onChange={(e) =>
            setDetailsInfo((curr) => ({
              ...curr,
              IncidentCause: e.target.value
            }))
          }
        />
        <label htmlFor="pciAtFault">Was PCI at fault for this strike?</label>
        <div id="pciAtFault" className="radio-container">
          {!unknown && (
            <>
              <label htmlFor="atFaultYes">
                YES
                <input
                  type="radio"
                  id="atFaultYes"
                  checked={detailsInfo.PciAtFault && !unknown}
                  onChange={() => {
                    setUnknown(false)
                    setDetailsInfo((curr) => ({
                      ...curr,
                      PciAtFault: !curr.PciAtFault
                    }))
                  }}
                />
              </label>
              <label htmlFor="atFaultNo">
                NO
                <input
                  type="radio"
                  id="atFaultNo"
                  checked={!detailsInfo.PciAtFault && !unknown}
                  onChange={() => {
                    setUnknown(false)
                    setDetailsInfo((curr) => ({
                      ...curr,
                      PciAtFault: !curr.PciAtFault
                    }))
                  }}
                />
              </label>
            </>
          )}
          <label htmlFor="atFaultUnknown">
            Unknown
            <input
              type="checkbox"
              id="atFaultUnknown"
              checked={unknown}
              onChange={() => {
                if (!unknown) {
                  setDetailsInfo((curr) => ({ ...curr, PciAtFault: false, PartyAtFault: "Unknown" }))
                } else {
                  setDetailsInfo((curr) => ({ ...curr, PciAtFault: false, PartyAtFault: "" }))
                }
                setUnknown((curr) => !curr)
              }}
            />
          </label>
        </div>
        {detailsInfo.PciAtFault && (
          <>
            <label htmlFor="drugtest">Was the employee responsible sent for a drug test?</label>
            <div id="drugtest" className="radio-container">
              <label htmlFor="testYes">
                YES
                <input
                  type="radio"
                  id="testYes"
                  name="test"
                  checked={detailsInfo.EmployeeDrugTested}
                  onChange={() => setDetailsInfo((curr) => ({ ...curr, EmployeeDrugTested: !curr.EmployeeDrugTested }))}
                />
              </label>
              <label htmlFor="testNo">
                NO
                <input
                  type="radio"
                  id="testNo"
                  name="test"
                  checked={!detailsInfo.EmployeeDrugTested}
                  onChange={() => setDetailsInfo((curr) => ({ ...curr, EmployeeDrugTested: !curr.EmployeeDrugTested }))}
                />
              </label>
            </div>
            {!detailsInfo.EmployeeDrugTested && detailsInfo.PciAtFault && (
              <>
                <label htmlFor="test-comments">Why wasn't the employee sent for a drug test?</label>
                <textarea
                  id="test-comments"
                  placeholder="Explain"
                  required={detailsInfo.PciAtFault && !detailsInfo.EmployeeDrugTested}
                  maxLength="1024"
                  value={detailsInfo.NoDrugTestComments || ""}
                  onChange={(e) => setDetailsInfo((curr) => ({ ...curr, NoDrugTestComments: e.target.value }))}
                />
              </>
            )}
          </>
        )}
        <div className={detailsInfo.PciAtFault ? "optional-field-hidden" : "optional-field-visible"}>
          <label htmlFor="atFaultParty">Who is responsible for the strike and why?</label>
          <textarea
            id="atFaultParty"
            className="description-textarea"
            rows="6"
            placeholder="Explain who you believe is at fault and why. These strikes end up going to court quite often so be sure to document the party at fault to support PCI's defense."
            required={!detailsInfo.PciAtFault}
            readOnly={unknown}
            maxLength="1024"
            value={detailsInfo.PartyAtFault}
            onChange={(e) =>
              setDetailsInfo((curr) => ({
                ...curr,
                PartyAtFault: e.target.value
              }))
            }
          />
        </div>
        <label htmlFor="gpsLocation">Are you currently at the location of the strike?</label>
        <div id="gpsLocation" className="radio-container">
          <label htmlFor="gpsYes">
            YES
            <input type="radio" id="gpsYes" checked={GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
          <label htmlFor="gpsNo">
            NO
            <input type="radio" id="gpsNo" checked={!GPS} onChange={() => setGPS((curr) => !curr)} />
          </label>
        </div>
        <div className={GPS ? "optional-field-visible" : "optional-field-hidden"}>
          {<GPSHelper active={GPS} addLocation={handleAddGps} />}
        </div>
        <div className="save-back-button-container">
          <button className="save-back-button" onClick={() => navigate("/UtilityStrikeInfo")}>
            Go Back
          </button>
          <input className="save-back-button" type="submit" value={saving ? "Saving" : "Save and continue"} />
        </div>
      </form>
    </div>
  )
}
