export default function Confirmation() {
  setTimeout(() => {
    localStorage.clear()
  }, 500)

  return (
    <div className="general-info-container">
      <h1>Report Submitted</h1>
      <p>
        <br />
        Someone from the PCI Safety team will reach out to you if there is any more information required about this
        report.
      </p>
    </div>
  )
}
