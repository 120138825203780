import "../Styles/Images.css"

export default function Image({ imageData, view }) {
  return (
    <div className="image-thumbnail" onClick={view}>
      <img
        src={imageData && imageData.startsWith("data") ? imageData : "data:image/png;base64," + imageData}
        alt="img"
        className="image"
      />
    </div>
  )
}
