import { useEffect, useRef, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { InjuryAcknowledgementInfo } from "../Classes/InjuryAcknowledgementInfo"
import { formatDate, sanitize } from "../Utils/HelperFunctions"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import SignatureCanvas from "react-signature-canvas"
import Imagemodel from "../Classes/ImageModel"
import {
  injuryInfo,
  injuryInfoSP,
  paragraphEight,
  paragraphEightSP,
  paragraphFive,
  paragraphFiveSP,
  paragraphFour,
  paragraphFourSP,
  paragraphNine,
  paragraphNineSP,
  paragraphOne,
  paragraphOneSP,
  paragraphSeven,
  paragraphSevenSP,
  paragraphSix,
  paragraphSixSP,
  paragraphThree,
  paragraphThreeSP,
  paragraphTwo,
  paragraphTwoSP
} from "../Utils/Constants"

export default function InjuredEmployeeInfo({ report, update, NA, setNA, spanish }) {
  //--- State variables ---//
  const [acknowledgementInfo, setAcknowledgementInfo] = useState(
    report.InjuryAcknowledgementInfo || new InjuryAcknowledgementInfo()
  )
  const navigate = useNavigate()
  const sigCanvas = useRef()

  //--- Error handler for missing report ---//
  useEffect(() => {
    if (!report || report.ReportType === "None") navigate("/Error")
  })

  return (
    <div className="general-info-container">
      <UnauthenticatedTemplate>
        <Navigate to="/Login" />
      </UnauthenticatedTemplate>
      <h2>Employee Injury Information</h2>
      <label htmlFor="empNA">Is the injured employee able to review and sign this document?</label>
      <div id="empNA" className="radio-container">
        <label htmlFor="NAYes">
          YES
          <input type="radio" id="NAYes" checked={!NA} onChange={() => setNA((curr) => !curr)} />
        </label>
        <label htmlFor="NANo">
          NO
          <input type="radio" id="NANo" checked={NA} onChange={() => setNA((curr) => !curr)} />
        </label>
      </div>
      {!NA && (
        <>
          <div className="injury-info-container">
            <p>
              <b>{spanish ? injuryInfoSP : injuryInfo}</b>
            </p>
            <ol>
              <li>{spanish ? paragraphOneSP : paragraphOne}</li>
              <br />
              <li>{spanish ? paragraphTwoSP : paragraphTwo}</li>
              <br />
              <li>{spanish ? paragraphThreeSP : paragraphThree}</li>
              <br />
              <li>{spanish ? paragraphFourSP : paragraphFour}</li>
              <br />
              <li>{spanish ? paragraphFiveSP : paragraphFive}</li>
              <br />
              <li>{spanish ? paragraphSixSP : paragraphSix}</li>
              <br />
              <li>{spanish ? paragraphSevenSP : paragraphSeven}</li>
              <br />
              <li>{spanish ? paragraphEightSP : paragraphEight}</li>
            </ol>
            <p>
              <b>{spanish ? paragraphNineSP : paragraphNine}</b>
            </p>
          </div>
          <label htmlFor="signature">
            <h3>Sign & date</h3>
          </label>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            value={formatDate(acknowledgementInfo.CreationDate)}
            onChange={(e) => {
              const newDate = new Date(e.target.value)
              newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60)
              setAcknowledgementInfo((curr) => ({
                ...curr,
                TimeOfSignature: newDate
              }))
            }}
          />
          <div className="signature-container" id="signature">
            <SignatureCanvas
              penColor="black"
              backgroundColor="#FFFFFF"
              canvasProps={{
                height: 300,
                className: "sig-canvas"
              }}
              ref={sigCanvas}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                backgroundColor: "black",
                padding: "5px"
              }}
            >
              <button
                className="save-back-button"
                onClick={() => {
                  sigCanvas.current.clear()
                  setAcknowledgementInfo((curr) => ({
                    ...curr,
                    Signature: new Imagemodel()
                  }))
                }}
              >
                CLEAR
              </button>
              <button
                className="save-back-button"
                onClick={() => {
                  setAcknowledgementInfo((curr) => ({
                    ...curr,
                    Signature: {
                      ...curr.Signature,
                      Base64: sanitize(sigCanvas.current.toDataURL("image/jpeg"))
                    }
                  }))
                }}
              >
                SIGN
              </button>
            </div>
          </div>
          {/* render existing signature if present */}
          {acknowledgementInfo.Signature && (
            <div style={{ display: "flex", marginTop: "1em" }}>
              <img src={acknowledgementInfo.Signature.Base64} alt="" style={{ width: "50%" }} />
            </div>
          )}
        </>
      )}
      <div className="save-back-button-container">
        <button className="save-back-button" onClick={() => navigate("/InjuryDetails")}>
          Go Back
        </button>
        <button
          className="save-back-button"
          onClick={async () => {
            if (!NA) await update(acknowledgementInfo)
            navigate("/Witnesses")
          }}
        >
          Save and Continue
        </button>
      </div>
    </div>
  )
}
