import React from "react"
import ReactDOM from "react-dom/client"
import { EventType, PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./Utils/msalConfig"
import { MsalProvider } from "@azure/msal-react"
import App from "./App"
import "./index.css"

const msalInstance = new PublicClientApplication(msalConfig)
msalInstance.initialize().then(() => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0 &&
    msalInstance.getActiveAccount() !== null
  ) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount())
  }

  msalInstance.addEventCallback((e) => {
    if (e.eventType === EventType.LOGIN_SUCCESS && e.payload.account) {
      const account = e.payload.account
      msalInstance.setActiveAccount(account)
    }
  })
})

ReactDOM.createRoot(document.getElementById("root")).render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)
